<template>
    <div id="finalizarCompra">
        <div class="top">
            <NavBarCardapio :pedidos="carrinho"/>
            <div class="container container-menu-cardapio" v-if="$store.getters.screen_size.width <= 767">                
                <div class="row">
                    <span class="titulo-cardapio">
                        {{titulo_step}}
                    </span>
                </div>
            </div>
        </div>
        <div class="corpo">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-12">
                        <div class="conteudo">
                            <div class="wrapper-btns" v-if="$store.getters.screen_size.width > 767">
                                <button class="voltar-cardapio" @click="voltarAoCardapio()">
                                    <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.964 20.3859C14.8022 20.47 14.6206 20.5085 14.4386 20.4973C14.2566 20.486 14.0811 20.4254 13.931 20.3219L0.931 11.3219C0.798162 11.2299 0.689607 11.107 0.61463 10.9639C0.539653 10.8207 0.500488 10.6615 0.500488 10.4999C0.500488 10.3383 0.539653 10.1791 0.61463 10.0359C0.689607 9.89279 0.798162 9.76994 0.931 9.67791L13.931 0.677907C14.081 0.5741 14.2566 0.513312 14.4387 0.502126C14.6208 0.49094 14.8025 0.529782 14.9641 0.614446C15.1257 0.69911 15.261 0.82637 15.3555 0.982447C15.45 1.13852 15.4999 1.31747 15.5 1.49991V19.4999C15.5 19.6824 15.4501 19.8615 15.3556 20.0177C15.2611 20.1739 15.1257 20.3012 14.964 20.3859Z" fill="#242424"/>
                                    </svg>
                                    Voltar ao cardápio
                                </button>
                                <button class="secondary-button" @click="cancelarPedido()">
                                    Cancelar pedido
                                </button>
                            </div>
                            <template>
                                <v-stepper v-model="e6" :vertical="$store.getters.screen_size.width <= 767 ? false : true" v-if="!mesa.id">
                                    <v-stepper-header>
                                        <v-stepper-step :complete="e6 > 1" step="1">
                                            Revise seu pedido                        
                                        </v-stepper-step>
                                        <v-stepper-step :complete="e6 > 2" step="2">
                                            Entrega
                                        </v-stepper-step>
                                        <v-stepper-step :complete="e6 > 3" step="3">
                                            Pagamento
                                        </v-stepper-step>
                                    </v-stepper-header>
                                    <v-stepper-content step="1">
                                        <div class="wrapper-itens-compra">
                                            <div class="itens-pedido">
                                                <ul>
                                                    <li v-for="(pedido,index) in carrinho" :key="index">
                                                        <div class="headers row" v-if="pedido.quantidade > 0">      
                                                            <div class="header-remove header-text col-12" v-if="$store.getters.screen_size.width <= 767">
                                                                <div class="remove-item item-text">
                                                                    <button class="btn-remove-item" @click="retiraItemPedido(pedido)">
                                                                        <v-icon color="#395185">mdi-minus-circle</v-icon>
                                                                    </button>
                                                                </div>
                                                            </div>                                                      
                                                            <div class="header-nome header-text col-lg-4 col-md-3 col-sm-8 col-12">
                                                                <span>Item</span>
                                                                <div class="nome-item item-text">
                                                                    {{pedido.nome}}
                                                                </div>
                                                            </div>
                                                            <div class="header-qtde header-text col-lg-1 col-md-2 col-sm-3 col-12">
                                                                <span>QTD</span>
                                                                <div class="qtde-item item-text">
                                                                    <div class="wrapper-add-remove">
                                                                        <button @click="pedido.quantidade--,setQtdItemPedido(pedido)" v-if="pedido.quantidade > 0">
                                                                            <span class="traco_um"></span>
                                                                        </button>
                                                                        <span>
                                                                            {{pedido.quantidade}}
                                                                        </span>
                                                                        <button @click="pedido.quantidade++,setQtdItemPedido(pedido)">
                                                                            <span class="traco_um"></span>
                                                                            <span class="traco_dois"></span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="header-observacoes header-text col-lg-4 col-md-4 col-12">
                                                                <span>Observações</span>
                                                                <div class="observacoes-item item-text">
                                                                    <p v-for="(grupo_complemento,index_grupo) in getComplementosByPorTipo(pedido,false)" :key="index_grupo">
                                                                        <span
                                                                            class="observacoes-item item-text"
                                                                            v-for="ingredientes in grupo_complemento.complementos"
                                                                            :key="ingredientes.id"
                                                                        >
                                                                            {{ingredientes.nome}}
                                                                        </span>
                                                                    </p>
                                                                    <p v-if="pedido.observacoes">
                                                                        <span>
                                                                            {{pedido.observacoes}}
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="header-valor header-text col-lg-2 col-md-2 col-12">
                                                                <span>Total</span>
                                                                <div class="valor-item item-text">
                                                                    {{(pedido.preco * pedido.quantidade).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                                                </div>
                                                            </div>
                                                            <div class="header-remove header-text col-1" v-if="$store.getters.screen_size.width > 767">
                                                                <div class="remove-item item-text">
                                                                    <button class="btn-remove-item" @click="retiraItemPedido(pedido)">
                                                                        <v-icon color="red">fas fa-trash-alt</v-icon>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="infos-item row" v-if="pedido.quantidade > 0">
                                                            <div class="col-12" v-for="(grupo_complemento,index_grupo) in getComplementosByPorTipo(pedido,true)" :key="index_grupo">
                                                                <div v-if="!grupo_complemento.por_quantidade">
                                                                    <div
                                                                        class="observacoes-item item-text"
                                                                        v-for="ingredientes in grupo_complemento.complementos"
                                                                        :key="ingredientes.id"
                                                                    >
                                                                        {{ingredientes.nome}}
                                                                    </div>
                                                                </div>

                                                                <div v-if="grupo_complemento.por_quantidade" class="extras-itens_">
                                                                    <div class="headers secondary-headers row" >
                                                                        <div class="secondary-headers-nome header-text col-12">
                                                                            {{grupo_complemento.nome}}
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="infos-item infos-extras row"
                                                                        v-for="complementos in grupo_complemento.complementos"
                                                                        :key="complementos.id"
                                                                    >
                                                                        <span class="qtde-item item-text">
                                                                            {{complementos.quantidade}}
                                                                        </span>
                                                                        <span class="nome-item item-text">
                                                                            {{complementos.nome}}
                                                                        </span>
                                                                        <span class="valor-item item-text">
                                                                            + {{(complementos.valor * complementos.quantidade).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <div class="col-lg-12 div-cupom" v-if="pedido.cupom_id != null">
                                                        <div class="row">
                                                            <div class="col-6 offset-6 cupom-selecionado">
                                                                <p>
                                                                    <span> Cupom selecionado:</span>
                                                                    {{cliente.cupons[indice_cupom].codigo_cupom}}
                                                                </p>
                                                                <p class="desconto-aplicado">
                                                                    - {{(valor__ - sub_total).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="wrapper-total row">
                                            <div class="col-lg-8 col-md-6 col-12 botoes" >
                                                <v-btn style="margin-left: 0; margin-right: 15px;" class="btn-avancar" @click="e6 = 2">
                                                    Avançar
                                                </v-btn>
                                                <v-btn v-if="pedido.cupom_id == null" class="btn-cupom" @click="listarCupons()">
                                                    Aplicar cupom
                                                </v-btn>
                                                <v-btn v-else class="btn-cupom remover" @click="pedido.cupom_id = null">
                                                    Remover cupom
                                                </v-btn>
                                            </div>
                                            
                                            <div class="total col-lg-4 col-md-6 col-12">
                                                <div class="titulo">
                                                    TOTAL
                                                </div>
                                                <div class="valor">
                                                    {{(sub_total).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                                </div>
                                            </div>
                                        </div>
                                    </v-stepper-content>                                    
                                    <v-stepper-content step="2">
                                        <div class="div-formas-entrega">
                                            <div class="wrapper-formas-de-entrega">
                                                <v-radio-group v-model="pedido['pedido_tipo_entrega_id']" mandatory >
                                                    <div class="wrapper-v-radio" v-if="frete.valor_frete != null">
                                                        <v-icon color="red">mdi-moped</v-icon>
                                                        <v-radio label="Entrega" value="1"/>
                                                        <span class="valor-da-entrega">
                                                            {{(frete.valor_frete).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                                        </span>
                                                    </div>
                                                    <div class="wrapper-v-radio">                                                        
                                                        <v-icon color="red">mdi-store</v-icon>
                                                        <v-radio label="Retirar na loja" value="2"/>
                                                    </div>
                                                </v-radio-group>
                                                <div class="wrapper-total-entrega row">
                                                    <div class="total col-12">
                                                        <div class="titulo">
                                                            Total
                                                        </div>
                                                        <div class="valor">
                                                            {{(valor_total_pedido).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="controles">
                                            <v-btn class="btn-avancar" @click="modalRetirada()">
                                                Avançar
                                            </v-btn>
                                            <v-btn class="btn-voltar" @click="e6 = 1">
                                                Voltar
                                            </v-btn>
                                        </div>
                                    </v-stepper-content>
                                    <v-stepper-content step="3">
                                        <div class="div-pagamentos">
                                            <div class="wrapper-formas-de-pagamento">
                                                <v-tabs v-model="pedido['pedido_pagamento_tipo_id']">
                                                    <v-tab v-if="formas_de_pagamento == 'Pagamento online' || formas_de_pagamento == 'Todas'" href="#1" @click="setaInfosCliente">Pague Através do Site</v-tab>
                                                    <v-tab v-if="formas_de_pagamento == 'Pagamento na entrega' || formas_de_pagamento == 'Todas'" href="#2">Pague na entrega</v-tab>
                                                </v-tabs>
                                                <v-tabs-items v-model="pedido['pedido_pagamento_tipo_id']">
                                                    <v-tab-item v-if="formas_de_pagamento == 'Pagamento online' || formas_de_pagamento == 'Todas'" value="1">
                                                        <v-card flat>
                                                            <v-card-text>
                                                                <Pagamento
                                                                    @retornoTransaction="retornoTransaction"
                                                                    :chave_financeiro="chave_financeiro"
                                                                    :formas_pagamento_disponiveis="formas_pagamento_disponiveis"
                                                                    @criaNovoPedido="criaNovoPedido"
                                                                    :codigo_usuario="codigo_usuario"
                                                                    :storeDelivery="$store"
                                                                    :valor_cobranca="valor_total_pedido.toString()"
                                                                    :texto_valor="texto_valor"
                                                                    :itens="itens"
                                                                    :texto_botao_pagar="texto_botao_pagar"
                                                                    :frete="frete"
                                                                    :cliente="cliente"
                                                                    :codigo_externo_cobranca="codigo_externo_cobranca"
                                                                    :itensPedido="carrinho"
                                                                    :codigo_empresa="empresa_id"
                                                                    @verificaCartao="verificaCartao"
                                                                    @atualizaCobranca="atualizaCobranca"
                                                                />
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-tab-item>
                                                    <v-tab-item v-if="formas_de_pagamento == 'Pagamento na entrega' || formas_de_pagamento == 'Todas'" value="2">
                                                        <v-card>
                                                            <PagamentoEntrega
                                                                @retornoTransaction="retornoTransaction"
                                                                :chave_financeiro="chave_financeiro"
                                                                :formas_pagamento_disponiveis="formas_pagamento_disponiveis"
                                                                :codigo_usuario="codigo_usuario"
                                                                :valor_cobranca="valor_total_pedido"
                                                                :texto_valor="texto_valor"
                                                                :itens="carrinho"
                                                                :texto_botao_pagar="texto_botao_pagar"
                                                                :frete="frete"
                                                                :cliente="cliente"
                                                                :codigo_externo_cobranca="codigo_externo_cobranca"
                                                                @atualizaFormaPagamento="atualizaFormaPagamento"
                                                            />
                                                        </v-card>
                                                    </v-tab-item>
                                                </v-tabs-items>
                                            </div>
                                            <div class="wrapper-total-entrega row">
                                                <div class="total col-12">
                                                    <div class="titulo">
                                                        Total
                                                    </div>
                                                    <div class="valor">
                                                        {{(valor_total_pedido).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="controles">
                                            <v-btn class="btn-finalizar-pedido" v-model="estado_botao_finalizar" :disabled="estado_botao_finalizar" @click="confirmaPagamento()">
                                                Finalizar Pedido
                                            </v-btn>
                                            <v-btn class="btn-voltar" @click="e6 = 2">
                                                Voltar
                                            </v-btn>
                                        </div>
                                    </v-stepper-content>
                                </v-stepper>
                                <v-stepper v-model="e6" :vertical="$store.getters.screen_size.width <= 767 ? false : true" v-else>
                                    <v-stepper-header>
                                        <v-stepper-step :complete="e6 > 1" step="1">
                                            Revise seu pedido                        
                                        </v-stepper-step>
                                    </v-stepper-header>
                                    <v-stepper-content step="1">
                                        <div class="wrapper-itens-compra">
                                            <div class="itens-pedido">
                                                <ul>
                                                    <li v-for="(pedido,index) in carrinho" :key="index">
                                                        <div class="headers row" v-if="pedido.quantidade > 0">      
                                                            <div class="header-remove header-text col-12" v-if="$store.getters.screen_size.width <= 767">
                                                                <div class="remove-item item-text">
                                                                    <button class="btn-remove-item" @click="retiraItemPedido(pedido)">
                                                                        <v-icon color="#395185">mdi-minus-circle</v-icon>
                                                                    </button>
                                                                </div>
                                                            </div>                                                      
                                                            <div class="header-nome header-text col-lg-4 col-md-3 col-sm-8 col-12">
                                                                <span>Item</span>
                                                                <div class="nome-item item-text">
                                                                    {{pedido.nome}}
                                                                </div>
                                                            </div>
                                                            <div class="header-qtde header-text col-lg-1 col-md-2 col-sm-3 col-12">
                                                                <span>QTD</span>
                                                                <div class="qtde-item item-text">
                                                                    <div class="wrapper-add-remove">
                                                                        <button @click="pedido.quantidade--,setQtdItemPedido(pedido)" v-if="pedido.quantidade > 0">
                                                                            <span class="traco_um"></span>
                                                                        </button>
                                                                        <span>
                                                                            {{pedido.quantidade}}
                                                                        </span>
                                                                        <button @click="pedido.quantidade++,setQtdItemPedido(pedido)">
                                                                            <span class="traco_um"></span>
                                                                            <span class="traco_dois"></span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="header-observacoes header-text col-lg-4 col-md-4 col-12">
                                                                <span>Observações</span>
                                                                <div class="observacoes-item item-text">
                                                                    <p v-for="(grupo_complemento,index_grupo) in getComplementosByPorTipo(pedido,false)" :key="index_grupo">
                                                                        <span
                                                                            class="observacoes-item item-text"
                                                                            v-for="ingredientes in grupo_complemento.complementos"
                                                                            :key="ingredientes.id"
                                                                        >
                                                                            {{ingredientes.nome}}
                                                                        </span>
                                                                    </p>
                                                                    <p v-if="pedido.observacoes">
                                                                        <span>
                                                                            {{pedido.observacoes}}
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div class="header-valor header-text col-lg-2 col-md-2 col-12">
                                                                <span>Total</span>
                                                                <div class="valor-item item-text">
                                                                    {{(getValorTotal(pedido)).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                                                </div>
                                                            </div>
                                                            <div class="header-remove header-text col-1" v-if="$store.getters.screen_size.width > 767">
                                                                <div class="remove-item item-text">
                                                                    <button class="btn-remove-item" @click="retiraItemPedido(pedido)">
                                                                        <v-icon color="red">fas fa-trash-alt</v-icon>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="infos-item row" v-if="pedido.quantidade > 0">
                                                            <div class="col-12" v-for="(grupo_complemento,index_grupo) in getComplementosByPorTipo(pedido,true)" :key="index_grupo">
                                                                <div v-if="!grupo_complemento.por_quantidade">
                                                                    <div
                                                                        class="observacoes-item item-text"
                                                                        v-for="ingredientes in grupo_complemento.complementos"
                                                                        :key="ingredientes.id"
                                                                    >
                                                                        {{ingredientes.nome}}
                                                                    </div>
                                                                </div>

                                                                <div v-if="grupo_complemento.por_quantidade" class="extras-itens_">
                                                                    <div class="headers secondary-headers row" >
                                                                        <div class="secondary-headers-nome header-text col-12">
                                                                            {{grupo_complemento.nome}}
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="infos-item infos-extras row"
                                                                        v-for="complementos in grupo_complemento.complementos"
                                                                        :key="complementos.id"
                                                                    >
                                                                        <span class="qtde-item item-text">
                                                                            {{complementos.quantidade}}
                                                                        </span>
                                                                        <span class="nome-item item-text">
                                                                            {{complementos.nome}}
                                                                        </span>
                                                                        <span class="valor-item item-text">
                                                                            + {{(complementos.valor * complementos.quantidade).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="infos-item row" v-if="pedido.quantidade > 0 && pedido.tipo_produto == 'pizza'">
                                                            <div class="col-12" v-for="(tipo,index_grupo) in getComplementosByPorTipoPizza(pedido)" :key="index_grupo">
                                                                <div class="extras-itens_">
                                                                    <div class="headers secondary-headers row" >
                                                                        <div class="secondary-headers-nome header-text col-12">
                                                                            {{index_grupo == 0 ? 'Borda' : ( index_grupo == 1 ? 'Massa' : 'Sabores' )}}
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="infos-item infos-extras row"
                                                                        v-for="tipos in tipo"
                                                                        :key="tipos.id"
                                                                    >
                                                                        <span class="qtde-item item-text">
                                                                            {{tipos.quantidade}}
                                                                        </span>
                                                                        <span class="nome-item item-text">
                                                                            {{tipos.nome}}
                                                                        </span>
                                                                        <span class="valor-item item-text">
                                                                            + {{(tipos.valor * tipos.quantidade).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <div class="col-lg-12 div-cupom" v-if="pedido.cupom_id != null">
                                                        <div class="row">
                                                            <div class="col-6 offset-6 cupom-selecionado">
                                                                <p>
                                                                    <span> Cupom selecionado:</span>
                                                                    {{cliente.cupons[indice_cupom].codigo_cupom}}
                                                                </p>
                                                                <p class="desconto-aplicado">
                                                                    - {{(valor__ - sub_total).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="wrapper-total row">
                                            <div class="col-lg-8 col-md-6 col-12 botoes" >
                                                <v-btn class="btn-finalizar-pedido" v-model="estado_botao_finalizar" @click="confirmaPedidoMesa()">
                                                    Confirmar
                                                </v-btn>
                                            </div>
                                            
                                            <div class="total col-lg-4 col-md-6 col-12">
                                                <div class="titulo">
                                                    TOTAL
                                                </div>
                                                <div class="valor">
                                                    {{(sub_total).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}}
                                                </div>
                                            </div>
                                        </div>
                                    </v-stepper-content>
                                </v-stepper>
                            </template>
                        </div>
                    </div>
                    <v-dialog v-model="modalAplicarCupom" max-width="600px">
                        <v-card id="modalAplicarCupom">
                            <IconeCupom/>
                            <div class="titulo-modal">
                                Adicione um cupom ao seu pedido!
                            </div>
                            <v-card v-if="cliente.cupons.length != 0">
                                <v-list>
                                    <v-list-item
                                        v-for="(cupom, index) in cliente.cupons"
                                        :key="cupom.cupom_id"
                                    >
                                        <v-list-item-icon>
                                            <v-icon>fas fa-ticket-alt</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content @click="modalAplicarCupom = false, pedido.cupom_id = cupom.cupom_id, indice_cupom = index">
                                            <v-list-item-title v-if="cupom.tipo_desconto == 1" v-text="'R$' + parseFloat(cupom.valor_desconto) + ' de desconto no pedido'">
                                            </v-list-item-title>
                                            <v-list-item-title v-if="cupom.tipo_desconto == 2" v-text="parseFloat(cupom.valor_desconto) + '% de desconto no pedido'">
                                            </v-list-item-title>
                                            <v-list-item-title style="font-weight: 700;" v-text="'CÓDIGO: ' + cupom.codigo_cupom">
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>
                            <v-card style="box-shadow: none;" v-else>
                                <p style="text-align: center;">
                                    Você não possui nenhum cupom disponível para este pedido!
                                </p>
                            </v-card>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="modalRetiradaPedido" max-width="500px">                        
                        <v-card class="modal-mensagem">
                            <v-card-title>
                                Selecione a forma de retirada
                            </v-card-title>
                            <div class="forma-de-retirada">
                                <v-select
                                    v-model="pedido.pedido_tipo_retiradas_id"
                                    :items= "formas_de_retirada"
                                    item-text="forma"
                                    item-value="value"
                                    filled
                                    label="Retirada do pedido"
                                    hide-details
                                />
                            </div>
                            <v-card-actions>
                                <button class="primary-button" @click="confirmarRetirada()">
                                    Confirmar
                                </button>
                                <button style="margin-left: 10px;" class="secondary-button" @click="modalRetiradaPedido = false">
                                    Cancelar
                                </button>
                            </v-card-actions>
                            <v-snackbar v-model="snackbar" timeout="2000">
                                Selecione uma forma de retirada
                                <template v-slot:action="{ attrs }">
                                    <v-btn color="orange" text v-bind="attrs" @click="snackbar = false">
                                        Fechar
                                    </v-btn>
                                </template>
                            </v-snackbar>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="modalTroco" max-width="max-content">
                        <v-card class="modal-mensagem">
                            <v-card-title>
                                Necessita de troco?
                            </v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <button class="primary-button" @click="modalTroco = false; modalValorDadoParaPagamento = true;">
                                    Sim
                                </button>
                                <v-spacer></v-spacer>
                                <button style="margin-left: 10px;" class="secondary-button" @click="salvarValorParaPagamento(0)">
                                    Não
                                </button>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="modalValorDadoParaPagamento" max-width="max-content">
                        <v-card class="modal-mensagem">
                            <v-card-title>
                                Troco para:
                            </v-card-title>
                            <v-card-text>
                                <v-text-field label="10,00" single-line v-model="valorParaPagamentoSemAtualizar" v-money="money"/>
                            </v-card-text>
                            <v-card-actions>
                                <button class="primary-button" @click="salvarValorParaPagamento(1)">
                                    Confirmar
                                </button>
                                <button style="margin-left: 10px;" class="secondary-button" @click="modalValorDadoParaPagamento = false">
                                    Fechar
                                </button>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <Loader v-if="loading"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import store from '@/store'
    import Loader from '@/components/Loader.vue'
    import NavBarCardapio from '@/components/NavBarCardapio.vue'
    import Pagamento from '@/components/Pagamento/Pagamento.vue'
    import PagamentoEntrega from '@/components/Pagamento/PagamentoEntrega.vue'
    import IconeCupom from '@/components/IconeCupom.vue'
    // import FormasDePagamento from '@/components/FormasDePagamento.vue'
	import store_financeiro from '@/components/Pagamento/store'

    export default {
        name: 'FinalizarCompra',
        components: {
            NavBarCardapio,
            Pagamento,
            PagamentoEntrega,
            IconeCupom,
            Loader
            // FormasDePagamento
        },
        data: () => ({
            loading: false,
            snackbar: false,
            tabFormasDePagamento: null,
            carrinho: null,
            radiosFormasDeEntrega: null,
            formas_de_retirada: [
                { forma: 'Encontrar com o entregador', value: 1 },
                { forma: 'Deixar na portaria', value: 2 },
                { forma: 'Deixar no portão/porta', value: 3 },
            ],
            valorDaEntrega: 10,
            modalTroco: false,
            modalValorDadoParaPagamento: false,
            modalRetiradaPedido: false,
            modalAplicarCupom: false,
            valorParaPagamento: null,
            valorParaPagamentoSemAtualizar: null,
            formas_pagamento_disponiveis:[
                'cartao_credito'
            ],
            money: {
                decimal: ',',
                thousands: '.',
                prefix: 'R$ ',
                suffix: '',
                precision: 2,
                masked: true /* doesn't work with directive */
            },
            chave_financeiro:store.getters.empresa_cardapio.chave_financeiro,
            empresa_id:store.getters.empresa_cardapio.codigo_empresa_id,
            empresa_url_amigavel: store.getters.empresa_cardapio.url_amigavel,
            codigo_usuario: null,
            valor_cobranca: null,
            texto_botao_pagar:'Finalizar Pedido',
            texto_valor:'Valor da Cobrança: ',
            dados_pedido: null,
            itens:[],
            frete:{
                "nome":undefined,
                "valor_frete": null,
                "envio_acelerado":true,
                "data_entrega":"",
                "endereco_entrega":{
                    "pais": "",
                    "estado": "",
                    "cidade": "",
                    "bairro": "",
                    "rua": "",
                    "numero": "",
                    "cep": ""
                }
            },
            cliente:{
                "nome": "",
                "tipo": "",
                "pais": "",
                "email": "",
                "documentos": [
                    {
                        "tipo": "",
                        "numero": ""
                    }
                ],
                "telefones": [""],
                "cupons": [""]
            },
            pedido:{
                empresa_servico_id: null,
                endereco_entrega_id: null,
                pedido_tipo_retiradas_id: null,
                produtos:[],
                pedido_tipo_entrega_id: null,
                pagamento:{
                    forma_pagamento: null,
                    valor_pagamento: null,
                },
                troco: null,
                sub_total: null,
                valor_beneficio: null,
                valor_total_pedido: null,
                valor_entrega: null,
                pedido_pagamento_tipo_id:null,
                cupom_id: null,
            },
            dados_cobranca:{},
            codigo_cartao_selecionado: '',
            // Stepper
            e6: 1,
            //pedido_id
            codigo_externo_cobranca:"1",
            formas_de_pagamento: null,
            indice_cupom: null,
            mesa: {
                id: null,
                nome: null,
            },
            total: 0,
        }),
        methods: {
            async init(){
                let urlParams = new URLSearchParams(window.location.search);
                
                let _mesa = urlParams.get('mesa') == undefined ? JSON.parse(localStorage.getItem('mesa_id')) : urlParams.get('mesa');
                console.log(_mesa)
                if(_mesa != undefined && _mesa  != ''&& _mesa  != null){
                    let resp = await store.dispatch('verificaMesa', {
                        codigo_empresa_id: store.getters.empresa_cardapio.codigo_empresa_id,
                        mesa_id: _mesa
                    });

                    if(resp.data.sucesso){
                        this.mesa.id = _mesa
                        this.mesa.nome = resp.data.dados.nome_mesa
                    }
                }
                var retorno_entrega = await store.dispatch('verificaValorEntrega', {codigo_endereco: store.getters.clientes.usuario.enderecos[0].codigo_endereco, codigo_empresa: store.getters.empresa_cardapio.codigo_empresa_servico})
                this.frete.valor_frete = retorno_entrega.valor_frete != undefined ? retorno_entrega.valor_frete : null;
                await this.loadPedido()
                await this.setaInfosCliente()
                await this.montaItens()
                await this.setaFormasPagamento()
                await this.minhaConta()
                
                if(this.carrinho.length == 0){
                    this.$swal({
                        title: 'Atenção',
                        icon:'warning',
                        text: 'Sacola vazia. Voltando para o cardápio...',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: true,
                        confirmButtonText:'Ok'
                    })
                    await this.$router.push('/' + this.$route.params.url_amigavel)
                }
            },
            async confirmaPagamento(){
                if (this.pedido.pagamento.forma_pagamento == 1) {
                    this.modalTroco = true
                }else{
                    await this.montaPedido()
                    await this.criaNovoPedido()
                }
            },
            async modalRetirada(){
                if(this.pedido['pedido_tipo_entrega_id'] == 1){
                    this.modalRetiradaPedido = true
                }
                else{
                    this.e6 = 3
                }
            },
            async confirmarRetirada(){
                if(this.pedido.pedido_tipo_retiradas_id != null){
                    this.modalRetiradaPedido = false
                    this.e6 = 3
                }
                else{
                    this.snackbar = true
                }
            },
            async montaPedido(){
                let endereco_entrega = JSON.stringify(sessionStorage.getItem('Código endereço de entrega'))
                endereco_entrega = endereco_entrega.replace('Código endereço de entrega', '')
                endereco_entrega = endereco_entrega.replace(/[^a-zA-Z0-9]/g, "")
                endereco_entrega = parseFloat(endereco_entrega)
                this.pedido.empresa_servico_id = store.getters.empresa_cardapio.codigo_empresa_servico
                this.pedido.endereco_entrega_id = JSON.parse(sessionStorage.getItem('Código endereço de entrega')) == null ? store.getters.clientes.usuario.enderecos[0].codigo_endereco : endereco_entrega
                this.pedido.troco = parseFloat(this.valorDoTroco != null ? this.valorDoTroco.toFixed(2) : 0)
                this.pedido.sub_total = parseFloat(this.valor_total_pedido.toFixed(2))
                this.pedido.valor_beneficio = 0
                this.pedido.pedido_tipo_retiradas_id = this.pedido['pedido_tipo_entrega_id'] == '1' ? this.pedido.pedido_tipo_retiradas_id : ''
                this.pedido.valor_entrega = this.pedido['pedido_tipo_entrega_id'] == '1' ? this.frete.valor_frete : 0
                this.pedido.valor_total_pedido = parseFloat(this.valor_total_pedido.toFixed(2))
                // Se método de pagamento for através do site
                if(this.pedido.pedido_pagamento_tipo_id == 1){
                    this.pedido.pagamento.forma_pagamento = 3
                    this.pedido.pagamento.valor_pagamento = this.pedido.valor_total_pedido
                }
                else{
                    this.pedido.pagamento.valor_pagamento = this.valorParaPagamento
                }

                this.montaItensDoPedido()
            },
            async salvarValorParaPagamento(necessitaTroco){
                let valorPedido = this.valor_total_pedido
                let valorAserDado = parseFloat(this.valorParaPagamento)
                let auxValorParaPagamento = (valorAserDado - valorPedido)
                this.valorDoTroco = auxValorParaPagamento

                if(necessitaTroco == 1){
                    if(auxValorParaPagamento >= 0 || this.pedido.pagamento.forma_pagamento != 1){
                        await this.montaPedido()
                        await this.criaNovoPedido()
                        this.modalValorDadoParaPagamento = !this.modalValorDadoParaPagamento
                    }else{
                        this.$swal({
                            title: 'Atenção',
                            icon:'warning',
                            text: 'Valor inserido é menor que o valor a ser pago!',
                            showCancelButton: false,
                            showCloseButton: false,
                            showConfirmButton: true,
                            confirmButtonText:'Ok'
                        })
                    }
                }
                else{
                    this.valorDoTroco = 0;
                    await this.montaPedido()
                    await this.criaNovoPedido()
                }
            },
            async loadPedido(){
                const dados_carrinho = await store.dispatch('verificaItensCarrinho', store.getters.carrinho)

                this.carrinho = dados_carrinho.carrinho;

                if(dados_carrinho.itens_removidos.length > 0){
                    let nomes_produtos = '';
                    for(let i = 0; i < dados_carrinho.itens_removidos.length; i++){
                        const element = dados_carrinho.itens_removidos[i];

                        nomes_produtos += element.nome + ( i < dados_carrinho.itens_removidos.length - 1 ? ', ' : '' )
                    }
                    this.$swal({
                        title: 'Atenção',
                        icon:'warning',
                        text: 'Alguns produtos foram removidos do seu carrinho por estarem indisponíveis no cardápio! (Produtos: ' + nomes_produtos + ')',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: true,
                        confirmButtonText:'Ok'
                    })
                }

                if(this.carrinho == null){
                    this.$router.push('/' + this.$route.params.url_amigavel)
                }
            },
            async voltarAoCardapio(){
                if(this.mesa.id){
                    this.$router.push('/' + this.$route.params.url_amigavel + '?mesa=' + this.mesa.id)
                }else{
                    this.$router.push('/' + this.$route.params.url_amigavel)
                }
            },
            async cancelarPedido(rota = true){
                this.pedido = []
                await store.dispatch('clearCarrinho')
                if(rota) this.$router.push('/' + this.$route.params.url_amigavel)
            },
            async atualizaCobranca(dados){
                this.dados_cobranca = dados
            },
            async retornoTransaction(retorno){
                if(retorno.sucesso){
                    await store.dispatch('clearCarrinho')
					this.$router.push('/' + this.$route.params.url_amigavel)
                }
            },
            async setaInfosCliente(){
                if(store.getters.clientes != undefined){
                    this.codigo_usuario = store.getters.clientes.usuario.id.toLocaleString()
                    this.cliente.nome = store.getters.clientes.usuario.nome
                    this.cliente.tipo = "individual"
                    this.cliente.pais = "br"
                    this.cliente.email = store.getters.clientes.usuario.email
                    this.cliente.documentos[0].tipo = store.getters.clientes.usuario.tipo_cliente == 'pf' ? "cpf" : "cnpj"
                    this.cliente.documentos[0].numero = store.getters.clientes.usuario.cpf
                    this.cliente.telefones[0] = '+55' + store.getters.clientes.usuario.telefone
                }
            },
            async montaItens(){
                let temp_itens_prod = []
                for (let index = 0; index < this.carrinho.length; index++) {
                    const element = this.carrinho[index];
                    temp_itens_prod.push({
                        codigo_externo: element.id,
                        nome: element.nome,
                        preco: element.preco,
                        quantidade: element.quantidade,
                        tangivel: true,
                    })
                }
                this.itens = temp_itens_prod
            },
            async atualizaFormaPagamento(forma_pagamento){
                this.pedido.pagamento.forma_pagamento = forma_pagamento
            },
            async montaItensDoPedido(){
                let temp_itens_prod = [];

                let temp_index_complementos = 0
                for (let index = 0; index < this.carrinho.length; index++) {
                    const element = this.carrinho[index];

                    temp_itens_prod[index] ={
                        produto_id: element.id,
                        codigo_tamanho: element.codigo_tamanho,
                        quantidade: element.quantidade,
                        tipo_produto: element.tipo_produto,
                        observacoes: element.observacoes,
                        valor: this.getValorTotal(element),
                        complementos: [],
                        borda: element.borda,
                        massa: element.massa,
                        sabores: element.sabores,
                    }
                    
                    if (element.grupo_complementos.length > 0) {
                        for (let indexExtra = 0; indexExtra < element.grupo_complementos.length; indexExtra++) {
                            const grupo = element.grupo_complementos[indexExtra];

                            if(grupo.complementos.length > 0){
                                for (let index2 = 0; index2 < grupo.complementos.length; index2++) {
                                    const element = grupo.complementos[index2];
                                    temp_itens_prod[index].complementos[temp_index_complementos] ={
                                        produto_complemento_id: element.id,
                                        quantidade: element.quantidade
                                    }
                                    temp_index_complementos++
                                    
                                }
                            }
                        }
                    }
                    temp_index_complementos = 0
                }
                this.pedido.produtos = temp_itens_prod
            },
            async criaNovoPedido(){
                this.loading = await true
                var resp = await store.dispatch('inserePedido', this.pedido)

                if (resp.sucesso) {
                    if(resp.pedido_id != undefined && this.pedido.pedido_pagamento_tipo_id == 1){
                        this.dados_cobranca['codigo_externo_cobranca'] = resp.pedido_id;
                        this.dados_cobranca['valor_cobranca'] = this.valor_total_pedido
                        this.dados_cobranca['frete'] = this.pedido.valor_entrega
                        this.gerarPagamento();
                        this.loading = await false
                    }else{
                        this.loading = await false

                        await this.cancelarPedido(false)

                        await this.$router.push('/' + this.$route.params.url_amigavel + '/meus-pedidos/' + resp.pedido_id)
                    }

                }else{
                    this.$swal({
                        title: 'Atenção',
                        icon:'warning',
                        text: 'Pedido não realizado!',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: true,
                        confirmButtonText:'Ok'
                    })
                    this.loading = await false
                }

                this.$emit('retornoTransaction',resp)
			},
            async confirmaPedidoMesa(){
                this.loading = await true
                await this.montaItensDoPedido()
                if(store.getters.pedido_mesa_id != null || localStorage.getItem('pedido_mesa_id') != undefined){
                    this.pedido.pedido_id = store.getters.pedido_mesa_id || localStorage.getItem('pedido_mesa_id')
                }
                this.pedido.usuario_id = store.getters.clientes.usuario.id
                this.pedido.empresa_servico_id = store.getters.empresa_cardapio.codigo_empresa_servico
                this.pedido.sub_total = parseFloat(this.valor_total_pedido.toFixed(2))
                this.pedido.valor_total_pedido = parseFloat(this.valor_total_pedido.toFixed(2)) + this.total
                this.pedido.mesa_id = store.getters.mesa_id || localStorage.getItem('mesa_id')

                var resp = await store.dispatch('inserePedidoMesa', this.pedido)
                
                if (resp.sucesso) {
                    if(resp.pedido_id != undefined){
                        this.loading = await false
                        this.$swal({
                            icon:'success',
                            text: 'Pedido realizado com sucesso!',
                            showCancelButton: false,
                            showCloseButton: false,
                            showConfirmButton: true,
                            confirmButtonText:'Ok'
                        })
                        this.carrinho.pedido_mesa_id = await resp.pedido_id
                    }

                    await store.dispatch('insereConta', this.carrinho)
                    await this.cancelarPedido()

                    this.voltarAoCardapio()
                }else{
                    this.$swal({
                        title: 'Atenção',
                        icon:'warning',
                        text: 'Não foi possível realizar o pedido!',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: true,
                        confirmButtonText:'Ok'
                    })
                    this.loading = await false
                }

                this.loading = await false
                this.$emit('retornoTransaction',resp)
			},
            async gerarPagamento(){
                if(this.dados_cobranca.cartao_selecionado != ''){
                    this.$swal({
                        title: 'Aguarde',
                        text: "Estamos validando e realizando o pagamento!",
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: false,
                        allowOutsideClick:false,
                        allowEscapeKey:false,
                    })

                    // faz a requisição para o back para coletar os grupos
                    var resp = await store_financeiro.dispatch('gerarPagamento', { dados_cobranca: this.dados_cobranca, codigo_empresa: this.empresa_id });

                    if(resp.status == 200){
                        if(!resp.data.sucesso){
                            this.$swal({
                                title: 'Atenção',
                                icon:'warning',
                                text: 'Erro ao processar seu pagamento!',
                                showCancelButton: false,
                                showCloseButton: false,
                                showConfirmButton: true,
                                confirmButtonText:'Ok'
                            })
                        }else{
                            this.$swal({
                                title: 'Atenção',
                                icon:'success',
                                text: 'Pagamento realizado com sucesso!',
                                showCancelButton: false,
                                showCloseButton: false,
                                showConfirmButton: true,
                                confirmButtonText:'Ok'
                            })

                            this.cancelarPedido()
                        }
                    }
                }else{
                    this.$swal({
                        title: 'Atenção',
                        icon:'warning',
					    text: 'Preencha todos os campos!',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: true,
                        confirmButtonText:'Ok'
                    })
                }
            },
            async retiraItemPedido(item){
                await store.dispatch('removeItemCarrinho', {item})

                if(this.pedido.cupom_id != null){
                    this.pedido.cupom_id = null
                    this.$swal({
                        title: 'Atenção',
                        icon:'warning',
                        text: 'Sacola vazia. Voltando para o cardápio...',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: true,
                        confirmButtonText:'Ok'
                    })
                }

                if(this.carrinho.length == 0){
                    this.$swal({
                        title: 'Atenção',
                        icon:'warning',
                        text: 'Sacola vazia. Voltando para o cardápio...',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: true,
                        confirmButtonText:'Ok'
                    })
                    await this.$router.push('/' + this.$route.params.url_amigavel)
                }
            },
            async setQtdItemPedido(item){
                await store.dispatch('setQtdItemCarrinho', {item})
                
                if(this.pedido.cupom_id != null){
                    this.pedido.cupom_id = null
                    this.$swal({
                        title: 'Atenção',
                        icon:'warning',
                        text: 'Seu pedido foi alterado, o cupom inserido foi removido!',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: true,
                        confirmButtonText:'Ok'
                    })
                }

                if(this.carrinho.length == 0){
                    this.$swal({
                        title: 'Atenção',
                        icon:'warning',
                        text: 'Sacola vazia. Voltando para o cardápio...',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: true,
                        confirmButtonText:'Ok'
                    })
                    await this.$router.push('/' + this.$route.params.url_amigavel)
                }
            },
            getValorTotal(pedido){
                console.log('getValorTotal')
                console.log(pedido)
                let total_ = 0
                total_ += pedido.preco * pedido.quantidade

                // if(pedido.grupo_complementos){
                //     pedido.grupo_complementos.forEach(grupo_complemento => {
                //         grupo_complemento.complementos.forEach(complemento => {
                //             if(complemento.valor != null ){
                //                 total_ += complemento.valor * complemento.quantidade
                //             }
                //         });
                //     });
                // }

                return total_
            },
            getComplementosByPorTipo(produto,por_quantidade){
                let complementos = [];
                for (let index = 0; index < produto.grupo_complementos.length; index++) {
                    const element = produto.grupo_complementos[index];
                    
                    if(por_quantidade == element.por_quantidade){
                        complementos.push(element);
                    }
                }
                return complementos
            },
            getComplementosByPorTipoPizza(produto){
                return [produto.borda, produto.massa, produto.sabores]
            },
            number_format(number, decimals, dec_point, thousands_sep) {
                number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
                var n = !isFinite(+number) ? 0 : +number,
                prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
                sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
                dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
                s = '',
                toFixedFix = function(n, prec) {
                    var k = Math.pow(10, prec);
                    return '' + (Math.round(n * k) / k).toFixed(prec);
                };
                s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
                if (s[0].length > 3) {
                    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
                }
                if ((s[1] || '').length < prec) {
                    s[1] = s[1] || '';
                    s[1] += new Array(prec - s[1].length + 1).join('0');
                }
                return s.join(dec);
            },
            replaceAll(campo,search,replace){
                return campo.replace(new RegExp('[' + search + ']', 'g'), replace); 
            },
            async verificaCartao(cartao_selecionado){
                if(cartao_selecionado != ''){
                    this.codigo_cartao_selecionado = true
                }
                else{                
                    this.codigo_cartao_selecionado = false
                }
            },
            async listarCupons(){

                let dados ={
                    empresa_id: this.empresa_id ,
                    codigo_usuario: this.codigo_usuario, 
                    sub_total: this.sub_total
                }

                var resp = await store.dispatch('getCupons', dados)

                if (resp.data.sucesso) {
                    this.cliente.cupons = resp.data.dados
                }

                this.modalAplicarCupom = true
            },
            async setaFormasPagamento(){
                
                var resp = await store.dispatch('getFormasPagamento', this.empresa_url_amigavel)

                if (resp.data.sucesso) {
                    this.formas_de_pagamento = resp.data.dados.formas_de_pagamento
                }
                
            },
            async minhaConta(){
                this.conta = store.getters.conta
                
                for(let i = 0; i < this.conta.length; i++){
                    this.total += this.conta[i].preco * this.conta[i].quantidade
                }
            },
        },
        watch:{
            'valorParaPagamentoSemAtualizar':function(newValue){
                let valor = this.replaceAll(this.replaceAll(this.replaceAll(newValue,'.', ''),',', '.'),'R$ ', '')
                this.valorParaPagamento = valor;
            },
        },
        mounted(){
            this.init()
        },
        computed: {
            valor__: function () {
                let valor__ = 0

                if(this.carrinho != null){
                    for (let index = 0; index < this.carrinho.length; index++) {
                        let valor_produto = 0
                        let valor_complemento = 0

                        const element = this.carrinho[index];

                        if(element.grupo_complementos != undefined){
                            element.grupo_complementos.forEach(elemento_grupo => {
                                for (let index = 0; index < elemento_grupo.complementos.length; index++) {
                                    const element = elemento_grupo.complementos[index];
                                    
                                    if (element.quantidade > 0 && !element.retirar) {
                                        valor_complemento = valor_complemento + (element.valor * element.quantidade)
                                    }
                                }
                            });
                        }

                        if(element.quantidade > 0){
                            valor_complemento = valor_complemento * element.quantidade
                            valor_produto = element.preco * element.quantidade;
                            
                        }
                        valor__ = valor__ + valor_produto + valor_complemento
                    }
                }

                if(valor__ < 0){
                    valor__ = 0 
                }
                return valor__
            },
            sub_total: function () {
                let valor__ = this.valor__
                let cupom = this.cliente.cupons
                
                if(this.pedido.cupom_id != null){
                    if(cupom[this.indice_cupom].tipo_desconto == 1){
                        valor__ -= cupom[this.indice_cupom].valor_desconto
                    }
                    else if(cupom[this.indice_cupom].tipo_desconto == 2){
                        valor__ = valor__ * ((100 - cupom[this.indice_cupom].valor_desconto)/100)
                    }
                }

                if(valor__ < 0){
                    valor__ = 0 
                }
                return valor__
            },
            valor_total_pedido: function () {
                let valor_total = this.sub_total

                if(this.pedido['pedido_tipo_entrega_id'] =='1'){
                    valor_total = valor_total + this.frete.valor_frete;
                }
                
                return valor_total
            },
            produto_por_tipo:function(){
                let produtos = [];

                for (let index = 0; index < this.carrinho.length; index++) {
                    const element = this.carrinho[index];
                    for (let index2 = 0; index2 < element.grupo_complementos.length; index2++) {
                        const element = element.grupo_complementos[index2];
                        if(produtos[element.id] == undefined){
                            produtos[element.id] = [];
                        }

                        if(produtos[element.id][element.por_quantidade] == undefined){
                            produtos[element.id][element.por_quantidade] = [];
                        }
                        
                        produtos[element.id][element.por_quantidade].push(element);
                    }
                }
                return produtos;
            },
            estado_botao_finalizar: function(){

                if(this.valor_total_pedido == 0){
                    // habilitado
                    return false
                }
                if(this.pedido.pedido_pagamento_tipo_id == 1){
                    if(this.codigo_cartao_selecionado == true){
                        // habilitado
                        return false
                    }
                    else{
                    // desabilitado
                        return true
                    }
                }
                if(this.pedido.pedido_pagamento_tipo_id == 2){
                    if(this.pedido.pagamento.forma_pagamento != null){
                        // habilitado
                        return false
                    }
                    else{
                        // desabilitado
                        return true
                    }
                }
                return true
            },
            titulo_step: function(){
                if(this.e6 == 2){
                    return 'Entrega'
                }
                if(this.e6 == 3){
                    return 'Pagamento'
                }
                else{
                    return 'Revise seu pedido'
                }
            },
        }
    }
</script>

<style lang="scss">
    #finalizarCompra{
        border-top: 1px solid #C8C8C8;
        @media (max-width: 767px){
            border-top: 0;
            .top{
                width: 100%;
                position: fixed;
                background-color: #fff;
                z-index: 2;
                #navBarCardapio{
                    .container-cardapio{
                        background-color: #262626;
                    }
                }
                .container-menu-cardapio{
                    background-color: #262626;                                  
                    .titulo-cardapio{
                        font-family: 'Poppins';
                        background-color: #262626;
                        width: 100%;
                        font-size: 22px;
                        color: #fff;
                        font-weight: 600;
                        padding-left: 15px;
                        @media (max-width: 375px){
                            font-size: 18px;
                        }
                    }
                }
            }
            .conteudo{
                padding-top: 150px;
                @media (max-width: 767px){
                    padding-top: 100px;
                }
            }  
        }
        .btn-avancar, .btn-finalizar-pedido{
            font-family: 'Poppins';
            margin-right: 15px;
            background-color: $primarycolor;
            border: 1px solid $primarycolor;
            border-radius: 30px;
            color: #fff;
            padding: 20px 25px;
            line-height: 18px;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0;
            transition: all cubic-bezier(0, 1.03, 0.57, 0.92) .3s;
            &.v-btn--disabled{
                border: 1px solid #d5d5d5;
            }
            &:hover{
                background-color: transparent;
                color: $primarycolor;
            }
            @media (max-width: 450px){
                font-size: 15px;
                padding: 15px 20px;
            }
        }
        .btn-avancar{
            @media (max-width: 450px){
                width: 120px;
            }
        }
        .btn-voltar, .btn-cupom{
            background-color: #000;
            border: 1px solid #000;
            border-radius: 30px;
            color: #fff;
            padding: 20px 25px;
            line-height: 18px;
            font-family: 'Poppins';
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0;
            width: max-content;
            transition: all cubic-bezier(0, 1.03, 0.57, 0.92) .3s;
            &:hover{
                background-color: transparent;
                color: #000;
            }
            &.remover{
             background-color: #f44336;
             border-color: #f44336;
             color: #fff;
            }
            @media (max-width: 450px){
                font-size: 15px;
                padding: 15px 20px;
                width: 120px;
            }
        }
        .btn-cupom{
            width: max-content;
        }
        .v-stepper{
            font-family: 'Poppins';
            .controles{
                @media (max-width: 767px){
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row-reverse;
                    button{
                        margin: 10px 3px;
                        font-size: 12px;
                    }
                }
            }
            .v-stepper__header{
                .v-stepper__label{
                    font-weight: 500;
                }
                @media (max-width: 767px){
                    box-shadow: none;
                    height: auto;
                    position: relative;
                    .v-stepper__step{
                        padding: 10px;
                        flex: 1;
                        transition: all cubic-bezier(0, 1.03, 0.57, 0.92) .3s;
                        &::after{
                            content: '';
                            flex: 1;
                            border: 1px solid #C4C4C4;
                            transform: translateX(10px);
                            transition: all cubic-bezier(0, 1.03, 0.57, 0.92) .3s;
                        }
                        &:last-of-type{
                            flex: initial;
                            &::after{
                                display: none;
                            }
                        }
                        .v-stepper__step__step{
                            background-color: #C4C4C4 !important;
                            border-color: #C4C4C4 !important;
                            color: #4F4F4F;
                            width: 46px;
                            height: 46px;
                            font-size: 18px;
                            font-weight: 700;
                            transition: all cubic-bezier(0, 1.03, 0.57, 0.92) .3s;
                        }
                        &.v-stepper__step--complete{
                            .v-stepper__step__step{
                                background-color: #FDAA1B !important;
                                border-color: #FDAA1B !important;
                                color: #fff;
                            } 
                            &::after{
                                border: 1px solid #FDAA1B;
                            }
                        }
                        &.v-stepper__step--active{
                            position: initial;
                            .v-stepper__label{
                                display: none;
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                right: 0;
                                font-family: Poppins;
                                font-style: normal;
                                font-weight: bold;
                                font-size: 28px;
                                line-height: 42px;
                                align-items: center;
                                text-align: center;
                                color: #FE8204;
                                text-shadow: none;
                            }
                            .v-stepper__step__step{
                                background-color: #FDAA1B !important;
                                border-color: #FDAA1B !important;
                                color: #fff;
                            }    
                        }
                    } 
                }
            }
            .v-stepper__content{
                border: none;
                .v-stepper__wrapper{
                    padding: 10px 0;
                }
                @media (max-width: 767px){
                    margin: -8px 0px -16px 0px !important;
                    border: none;
                    padding: 0;
                }
                
            }      
            @media (max-width: 767px){
                box-shadow: none;
            }      
        }
        .corpo{
            .wrapper-btns{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;
                .voltar-cardapio{
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 27px;
                    text-transform: uppercase;
                    color: #242424;
                    display: flex;
                    align-items: center;
                    svg{
                        margin-right: 14px;
                    }
                }
                .secondary-button{
                    padding: 15px 20px;
                    border-width: 2px;
                    font-size: 16px;
                }
                @media (max-width: 463px){
                    justify-content: center;
                    .voltar-cardapio{
                        margin-bottom: 15px;
                    }
                }
            }
            .wrapper-itens-compra{
                padding-top: 10px;
                .titulo{
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: bold;
                    font-size: 30px;
                    line-height: 54px;
                    display: flex;
                    align-items: center;
                    color: #242424;
                    @media (max-width: 320px){
                        font-size: 25px;
                    }
                }
                .itens-pedido{
                    // padding-top: 24px;
                    ul{
                        border-bottom: 1px solid #242424;
                        list-style: none;
                        padding: 20px 0 0px 0;
                        li{
                            padding: 5px 0;
                            border-top: 1px solid #e0e0e0;
                            .headers{
                                .header-text{
                                    display: flex;
                                    flex-wrap: wrap;
                                    flex-direction: column;
                                    font-family: 'Poppins';
                                    font-style: normal;
                                    font-weight: bold;
                                    font-size: 16px;
                                    line-height: 27px;
                                    color: #242424;
                                    @media (max-width: 767px){
                                        padding: 0 12px;
                                        display: flex;
                                        flex-direction: row;
                                        span{
                                            margin-right: 5px;
                                        }
                                    }
                                    &.header-nome{
                                        justify-content: flex-start;
                                    }
                                    &.header-qtde{
                                        text-align: center;
                                    }
                                    &.header-observacoes{
                                        p{
                                            margin-bottom: 0;
                                        }
                                        span{
                                            margin-right: 5px;
                                            &:last-of-type{
                                                margin-right: 0;
                                            }
                                        }
                                    }
                                    &.header-remove{
                                        justify-content: center;
                                    }                                                                       
                                    .item-text{
                                        font-family: 'Poppins';
                                        font-style: normal;
                                        font-weight: 300;
                                        font-size: 16px;
                                        line-height: 30px;
                                        color: #242424;
                                        display: flex;
                                        justify-content: flex-start;
                                        @media (max-width: 767px){
                                            font-size: 15px !important;
                                            font-weight: 300 !important;
                                        }
                                        &.qtde-item{
                                            font-weight: bold;
                                            font-size: 18px;
                                            .wrapper-add-remove{
                                                display: flex;
                                                align-items: center;
                                                justify-content: space-between;
                                                width: 100%;
                                                @media (max-width: 767px){
                                                    justify-content: flex-end;
                                                    span{
                                                        margin: 0;
                                                        width: 24px;
                                                    }
                                                }
                                                button{
                                                    position: relative;
                                                    width: 25px;
                                                    height: 25px;
                                                    span{
                                                        position: absolute;
                                                        height: 4px;
                                                        width: 15px;
                                                        background-color: #FE5C1D;
                                                        transition: all cubic-bezier(0, 1.03, 0.57, 0.92) .3s;
                                                        @media (max-width: 767px){
                                                            height: 2px;
                                                            width: 13px;
                                                        }
                                                        &.traco_um{
                                                            top: 50%;
                                                            left: 50%;
                                                            transform: translate(-50%, -50%);
                                                        }
                                                        &.traco_dois{
                                                            top: 50%;
                                                            left: 50%;
                                                            transform: translate(-50%, -50%) rotate(90deg);
                                                        }
                                                        &.dropIconOpcoes{
                                                            position: relative;
                                                            height: inherit;
                                                            width: inherit;
                                                            background-color: transparent;
                                                            svg{
                                                                transition: all cubic-bezier(0, 1.03, 0.57, 0.92) .3s;
                                                            }
                                                        }
                                                    }
                                                    &.opcoesAtivas{
                                                        .dropIconOpcoes{
                                                            svg{
                                                                transform: rotate(180deg);
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        &.observacoes-item{
                                            font-weight: 500;
                                            font-size: 16px;
                                            .item-text{
                                                margin-right: 5px;
                                                &::after{
                                                    content: ', ';
                                                }
                                            }
                                        }
                                        &.valor-item{
                                            font-weight: bold;
                                            font-size: 18px;
                                            @media (max-width: 767px){
                                                font-size: 18px !important;
                                                font-weight: 700 !important;
                                            }
                                        }
                                        &.remove-item{
                                            display: flex;
                                            flex-wrap: wrap;
                                            align-items: flex-end;
                                            justify-content: center;
                                            .wrapper-add-remove{
                                                display: flex;
                                                align-items: center;
                                                justify-content: space-between;
                                                width: 100%;
                                                button{
                                                    position: relative;
                                                    width: 40px;
                                                    height: 40px;
                                                    &.dropOpcoesExtras{
                                                        height: 30px;
                                                    }
                                                    span{
                                                        position: absolute;
                                                        height: 5px;
                                                        width: 26px;
                                                        background-color: #FE5C1D;
                                                        transition: all cubic-bezier(0, 1.03, 0.57, 0.92) .3s;
                                                        &.traco_um{
                                                            top: 50%;
                                                            left: 50%;
                                                            transform: translate(-50%, -50%);
                                                        }
                                                        &.traco_dois{
                                                            top: 50%;
                                                            left: 50%;
                                                            transform: translate(-50%, -50%) rotate(90deg);
                                                        }
                                                        &.dropIconOpcoes{
                                                            position: relative;
                                                            height: inherit;
                                                            width: inherit;
                                                            background-color: transparent;
                                                            svg{
                                                                transition: all cubic-bezier(0, 1.03, 0.57, 0.92) .3s;
                                                            }
                                                        }
                                                    }
                                                    &.opcoesAtivas{
                                                        .dropIconOpcoes{
                                                            svg{
                                                                transform: rotate(180deg);
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                            .btn-remove-item{
                                                i{
                                                    font-size: 20px;
                                                }
                                            }
                                        }
                                        @media (max-width: 767px){
                                            flex: 1;
                                            justify-content: flex-end;
                                        }
                                    }
                                    @media (max-width: 1060px){
                                        font-size: 13px;
                                    }
                                }
                                &.secondary-headers{
                                    .secondary-headers-nome{
                                        justify-content: flex-start;
                                    }
                                }
                            }
                            .infos-item{      
                                @media (max-width: 767px){
                                    .extras-itens_{
                                        display: flex;
                                        .secondary-headers{
                                            flex: initial;
                                            width: auto;
                                            .secondary-headers-nome{
                                                flex: initial;
                                                width: auto;
                                            }
                                        }
                                    }                      
                                } 
                                &.infos-extras{
                                    padding-left: 15px;
                                    .qtde-item{
                                        margin-right: 10px;
                                        text-align: center;
                                    }
                                    .nome-item{
                                        margin-right: 10px;
                                        justify-content: center;
                                    }
                                    .valor-item{
                                       font-weight: 500;
                                        font-size: 16px;
                                        text-align: right;
                                    }
                                    @media (max-width: 767px){
                                        display: flex;
                                        flex-wrap: wrap;
                                        justify-content: flex-end;
                                        align-items: center;
                                        flex: 1;
                                        font-size: 15px !important;
                                        font-weight: 300 !important;
                                        margin: 0 !important;
                                        font-family: 'Poppins';
                                        font-style: normal;
                                        font-weight: 300;
                                        font-size: 16px;
                                        line-height: 30px;
                                        color: #242424;
                                        .valor-item{
                                            font-size: 15px !important;
                                            font-weight: 300 !important;
                                            margin: 0 !important;
                                            font-family: 'Poppins';
                                            font-style: normal;
                                            font-weight: 500;
                                            font-size: 16px;
                                            line-height: 30px;
                                            color: #242424;
                                        }
                                    }
                                }
                            }
                            .header-remove{
                                justify-content: center;
                            }    
                            .some{
                                display: none!important;
                            }
                            &:nth-child(1){
                                border: none;
                            }
                        }
                        .div-cupom{
                                padding-bottom: 0px;
                            .cupom-selecionado{
                                display: flex;
                                flex-wrap: nowrap;
                                justify-content: space-around;
                                p{
                                    color: #000;
                                    margin: 0;
                                    font-weight: 400;
                                    text-align: center;
                                    span{
                                        font-weight: 700;
                                    }
                                }
                                .desconto-aplicado{
                                    color: #F44336;
                                    font-weight: 700;
                                    margin: 0;
                                }
                            }                     
                        }
                        @media (max-width: 767px){
                            .header-remove{
                                .remove-item{
                                    justify-content: flex-end !important;
                                    display: flex;
                                }
                            }
                            padding-top: 0;
                        }
                    }
                }
            }
            .wrapper-total, .wrapper-total-entrega{
                justify-content: flex-end;
                .botoes{
                    display: flex;
                    align-items: center;
                }
                .total{
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-start;
                    padding-top: 0;
                    .titulo{
                        display: flex;
                        align-items: center;
                        height: 100%;
                        font-weight: 500;
                        font-size: 28px;
                        line-height: 42px;
                        color: #242424;
                        font-family: 'Poppins';
                        margin-right: 10px;
                        @media (max-width: 500px){
                            font-size: 18px;
                        }
                    }
                    .valor{
                        display: flex;
                        align-items: center;
                        font-weight: bold;
                        font-size: 25px;
                        line-height: 42px;
                        text-transform: uppercase;
                        color: #242424;
                        border-top: solid 1px #242424;
                        border-bottom: solid 2px #242424;
                        border-right: solid 2px #242424;
                        border-left: solid 2px #242424;
                        padding: 15px 30px;
                        font-family: 'Poppins';
                        height: 100%;
                        @media (min-width: 960px) and (max-width: 1119px){
                            border: none;
                        }
                        @media (max-width: 500px){
                            font-size: 18px;
                            padding: 5px 25px;
                        }
                    }
                }
            }
            .wrapper-total-entrega{
                .total{
                    justify-content: flex-start;
                    .titulo{
                        font-size: 20px;
                    }
                    .valor{
                        font-size: 20px;
                        padding: 5px 20px;
                        border: none;
                    }
                    @media (max-width: 490px){
                        justify-content: center;
                    }
                }
            }
            .div-pagamentos,
            .div-formas-entrega{
                .titulo{
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: bold;
                    font-size: 30px;
                    line-height: 54px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #242424;                    
                    @media (max-width: 380px){
                        font-size: 25px;
                    }
                    @media (max-width: 320px){
                        font-size: 22px;
                    }
                }
                .wrapper-formas-de-entrega,
                .wrapper-formas-de-pagamento{
                    .v-tabs{
                        .v-item-group{
                            .v-slide-group__wrapper{
                                .v-slide-group__content{
                                    a{
                                        font-family: 'Poppins';
                                        font-style: normal;
                                        font-weight: bold;
                                        font-size: 16px;
                                        line-height: 27px;
                                        text-transform: uppercase;
                                        &:before{
                                            content: none;
                                        }
                                    }
                                }
                            }
                            @media (max-width: 490px){
                                display: block;
                                .v-slide-group__content{
                                    display: block;
                                }
                            }
                        }
                    }
                    .v-item-group{
                       .v-window__container{
                           .v-window-item{
                               .v-card{
                                   .v-card__text{
                                       .v-input{
                                           .v-input__control{
                                               .v-input__slot{
                                                   .v-input--radio-group__input{
                                                       .wrapper-v-radio{
                                                            display: flex;
                                                            flex-wrap: wrap;
                                                            align-items: center;
                                                            padding-bottom: 12px;
                                                            min-height: 40px;
                                                            .v-radio{
                                                                label{
                                                                    font-family: 'Poppins';
                                                                    font-style: normal;
                                                                    font-weight: 300;
                                                                    font-size: 16px;
                                                                    line-height: 27px;
                                                                    text-align: center;
                                                                    color: #000000;
                                                                }
                                                                .primary--text{
                                                                    color: inherit !important;
                                                                    caret-color: inherit !important;
                                                                }
                                                            }
                                                            .v-icon{
                                                                &.mdi{
                                                                    margin-right: 10px;
                                                                    font-size: 50px;
                                                                    color: $primarycolor!important;
                                                                }
                                                                &.mdi-cash{
                                                                    color: green!important;
                                                                }
                                                            }
                                                            @media (max-width: 290px){
                                                                justify-content: center;
                                                            }
                                                       }
                                                   }
                                               }
                                           }
                                       }
                                   }
                               }
                           }
                       } 
                    }
                }
                .wrapper-formas-de-entrega{
                    .v-input--radio-group{
                        .v-input__control{
                            .v-input__slot{
                                .v-input--radio-group__input{
                                    .wrapper-v-radio{
                                        display: flex;
                                        flex-wrap: wrap;
                                        align-items: center;
                                        padding-bottom: 12px;
                                        min-height: 40px;
                                        .v-radio{
                                            margin-bottom: 0;
                                            label{
                                                font-family: 'Poppins';
                                                font-style: normal;
                                                font-weight: 300;
                                                font-size: 16px;
                                                line-height: 27px;
                                                text-align: center;
                                                color: #000000;
                                            }
                                            .primary--text{
                                                color: inherit !important;
                                                caret-color: inherit !important;
                                            }
                                        }
                                        .v-icon{
                                            &.mdi{
                                                margin-right: 10px;
                                                font-size: 50px;
                                                color: $primarycolor!important;
                                            }
                                        }
                                        .valor-da-entrega{
                                            font-family: 'Poppins';
                                            font-style: normal;
                                            font-weight: 700;
                                            font-size: 18px;
                                            line-height: 27px;
                                            text-align: center;
                                            color: #000000;
                                            margin-left: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .card-form{
                .card-list{
                    .card-item{
                        .card-item__side{
                            .card-item__cover{
                                background-image: none!important;
                                background: #242424!important;
                            }
                        }
                    }
                }
                .card-form__inner{
                    .primary-button{
                        align-items: center;
                        justify-content: center;
                        border-width: 2px;
                        &.cancelar-cartao{
                            background-color: #FF5252;
                            border-color: #FF5252;
                            &:hover{
                                background-color: #fff;
                                color: #FF5252;
                            }
                        }
                    }
                }
            }
        }
    }    
    .v-card{
        font-family: 'Poppins';
        .v-card__title{
            font-weight: 600;
        }
    }
    .forma-de-retirada{
        font-family: 'Poppins';
        font-weight: 500;
        padding: 15px;
    }
    #modalAplicarCupom{
        padding: 20px;
        font-family: 'Poppins';
        .titulo-modal{
            text-align: center;
            font-weight: 600;
            padding-bottom: 15px;
        }    
        .v-card{
            box-shadow: none;
        }
        .v-list-item{
            cursor: pointer;            
            box-shadow: 0 3px 1px -2px rgba(0,0,0,0.2), 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12);
            margin-bottom: 10px;
            color: #000;
            font-weight: 400;
            transition: all ease .3s;
            .v-list-item__icon{
                svg{
                    path{
                        fill: #15be79;
                    }
                }
            }
            .v-list-item__title{
                transition: all cubic-bezier(0, 1.03, 0.57, 0.92) .3s;
            }
            &:hover{
                .v-list-item__title{
                    color: $primarycolor;
                }
            }
        }
        img{
            width: 100%;
        }
    }
</style>