<template>
	<div id="opcoesDeCartao">
        <v-dialog v-model="cadastrar_cartao" persistent max-width="600" :fullscreen="$store.getters.screen_size.width <= 767 ? true : false">
            <v-card>
                <button id="close-modal" @click="cancelarCartao">
                    <v-icon dark color="#FE8204">
                        mdi-close
                    </v-icon>
                </button>
                <CardForm
                    :form-data="formData"
                    @salvar-cartao="salvarCartao"
                    @cancelar-cartao="cancelarCartao"
                    @busca-cep="buscaCep"
                />
            </v-card>
        </v-dialog>

        <v-dialog 
            v-model="pagar_pix" 
            persistent 
            max-width="600" 
            :fullscreen="$store.getters.screen_size.width <= 767"
        >
            <v-card>
                <v-card-title class="d-flex justify-space-between">
                <span>Pagamento via PIX</span>
                <v-btn icon @click="cancelarPix">
                    <v-icon color="#FE8204">mdi-close</v-icon>
                </v-btn>
                </v-card-title>

                <v-card-text>
                <!-- Opções de pagamento -->
                <v-radio-group v-model="opcaoPagamento" row>
                    <v-radio label="Pagar via QR Code" value="qrcode" @click="gerarQrCode"></v-radio>
                    <v-radio label="Pagar via Chave de Acesso" value="chave" @click="gerarQrCode"></v-radio>
                </v-radio-group>

                <!-- Pagamento via QR Code -->
                <div v-if="opcaoPagamento === 'qrcode'" >
                    <p>Escaneie o código QR abaixo para realizar o pagamento:</p>
                    <v-img 
                    :src="url_qrcode" 
                    aspect-ratio="1" 
                    max-width="300"
                    style="margin-left: 60px;"
                    ></v-img>
                </div>

                <!-- Pagamento via Chave de Acesso -->
                <div v-if="opcaoPagamento === 'chave'">
                    <p>Use a chave de acesso abaixo para realizar o pagamento:</p>
                    <v-text-field
                        v-model="chaveAcesso"
                        readonly
                        label="Chave de Acesso"
                        prepend-icon="mdi-content-copy"
                     ></v-text-field>
                    <!-- @click:prepend="copiarChaveAcesso" -->
                </div>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green" @click="confirmarPagamento">Confirmar Pagamento</v-btn>
                <v-btn color="red" @click="cancelarPix">Cancelar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-container>
            <v-row dense>
                <v-col
                    v-for="(cartao, i) in cartoes"
                    :key="i"
                    cols="12"
                    @click="dados_cobranca.cartao_selecionado = dados_cobranca.cartao_selecionado == cartao.codigo_cartao ? '' : cartao.codigo_cartao"                
                >
                <v-card color="#0e2a5acc" dark>
                    <div class="d-flex flex-no-wrap justify-space-between">
                        <div class="wrapper-cartao">
                            <v-col cols="12">
                                <v-row>
                                    <v-col cols="lg-8 col-12" class="wrapper-radio-text">
                                        <input type="radio" id="cartao.codigo_cartao" :value="cartao.codigo_cartao" v-model="dados_cobranca.cartao_selecionado">
                                        <v-card-title
                                            class="text-h5"
                                            v-text="cartao.primeiro_digitos + ' ** **** ****'"
                                        >
                                        </v-card-title>
                                    </v-col>
                                    <v-col cols="lg-4 col-12" class="wrapper-deletar-cartao">
                                        <v-card-actions>
                                            <v-btn
                                                class="ml-2 mt-5"
                                                outlined
                                                rounded
                                                small
                                                @click="apagarCartao(cartao.codigo_cartao)"
                                            >
                                                Deletar Cartão
                                            </v-btn>
                                        </v-card-actions>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </div>
                    </div>
                </v-card>
                </v-col>
            </v-row>

            <v-row dense>
                <v-col cols="12" class="wrapper-btn-cadastrar-cartao">
                    <v-btn     
                    class="ml-2 mt-5 black-button"
                    @click="cadastrar_cartao = true"
                    outlined
                    rounded
                    small
                    >
                    Cadastrar Cartão
                    </v-btn>
                </v-col>
  
                <v-col cols="12" class="wrapper-btn-cadastrar-cartao">
                    <v-btn     
                    class="ml-2 mt-5 black-button"
                    @click="pagar_pix = true"
                    outlined
                    rounded
                    small
                    >
                    Pagar com PIX
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
	</div>
</template>

<script>
	// importa o store
	import store from './store'
    import store_pedido from '../../store'

	import CardForm from './Cartao/components/CardForm.vue'
		export default {
		name: 'app',
		components: {
			CardForm
		},
        props:{
            chave_financeiro:[String],
            retornoTransaction:[Function],
            codigo_usuario:[String],
            valor_cobranca:[String],
            texto_valor:[String],
            itens:[Array],
            texto_botao_pagar:[String],
            frete:[Object],
            cliente:[Object],
            codigo_externo_cobranca:[String],
            itensPedido:[Array],
            storeDelivery:[Object],
            forma_de_entrega:[String],
            codigo_cartao_selecionado:[Boolean],
            codigo_empresa:[Number],
        },
		data () {
			return {
				formData: {
					nome_cartao: '',
					numero_cartao: '',
					mes_validade_cartao: '',
					ano_validade_cartao: '',
					cvv_cartao: '',
					pais:'br',
					cep:'',
					rua:'',
					cidade:'',
					estado:'',
					bairro:'',
					numero:'',
                    codigo_usuario:this.codigo_usuario
				},
				cadastrar_cartao:false,
                pagar_pix:false,
                opcaoPagamento:'',
                cartoes:[],
                dados_cobranca:{
                    frete:this.frete,
                    cliente:this.cliente,
                    itens:this.itens,
                    valor_cobranca:this.valor_cobranca,
                    cartao_selecionado:'',
                    codigo_externo_cobranca:this.codigo_externo_cobranca,
                    codigo_usuario:this.codigo_usuario,
                    forma_pagamento:'cartao_credito'
                },
                pedido:{
                    empresa_servico_id: null,
                    endereco_entrega_id: null,
                    produtos:[],
                    pedido_tipo_id: null,
                    pagamento:{
                        forma_pagamento: null,
                        valor_pagamento: null,
                    },
                    troco: null,
                    sub_total: null,
                    valor_beneficio: null,
                    valor_total_pedido: null,
                    valor_entrega: null,
                    pagamento_tipo_id:null
                },
                url_qrcode:'',
                chaveAcesso:'',
                pix_id:'',
                total: 0,
                
			}
		},
		methods: {
            async init(){
                this.cadastrar_cartao = false;
                this.pagar_pix = false;
                this.getCartoes();
            },
			cancelarCartao(){
				this.cadastrar_cartao = false;
                
			},
            cancelarPix(){
                this.pagar_pix = false;
                this.opcaoPagamento = '';
            },

            async gerarQrCode(){
                this.url_qrcode = ''
                this.chaveAcesso= ''
                this.dados_cobranca.forma_pagamento = 'pix'
                this.dados_cobranca.codigo_externo_cobranca = 'pix_'+ this.codigo_externo_cobranca
                var resp = await store.dispatch('gerarQRCode', { dados_cobranca: this.dados_cobranca, codigo_empresa: this.codigo_empresa });
                this.url_qrcode = resp.data.dados.qr_code_url
                this.chaveAcesso = resp.data.dados.qr_code_url
                this.pix_id = resp.data.dados.id
                
            },

            async cancelarPedido(rota = true){
                this.pedido = []
                await store_pedido.dispatch('clearCarrinho')
                if(rota) this.$router.push('/' + this.$route.params.url_amigavel)
            },

            async confirmarPagamento(){
                var resp = await store.dispatch('verificaPagamentoPix', { dados_cobranca: this.dados_cobranca, codigo_empresa: this.codigo_empresa, pix_id:this.pix_id });
                
                if(resp.data.dados.cobranca_status_id == 14){
                    this.montaPedido()
                    
                    var resposta = await store_pedido.dispatch('inserePedido', this.pedido)
                    console.log('RESPOSTA PEDIDO->',JSON.stringify(resposta))

                    if (resposta.sucesso) {
                        if(resposta.pedido_id != undefined){
                            this.$swal({
                                title: 'Atenção',
                                icon:'success',
                                text: 'Pagamento recebido com sucesso!',
                                showCancelButton: false,
                                showCloseButton: false,
                                showConfirmButton: true,
                                confirmButtonText:'Ok'
                            })

                            await this.cancelarPedido(false)

                            await this.$router.push('/' + this.$route.params.url_amigavel + '/meus-pedidos/' + resposta.pedido_id)
                            
                         }

                    }

                    
                }else{
                    this.$swal({
                        title: 'Atenção',
                        icon:'warning',
                        text: 'Pagamento não recebido!',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: true,
                        confirmButtonText:'Ok'
                    })
                }

                this.pagar_pix = false;
                

            },

            async montaPedido(){
                let endereco_entrega = JSON.stringify(sessionStorage.getItem('Código endereço de entrega'))
                endereco_entrega = endereco_entrega.replace('Código endereço de entrega', '')
                endereco_entrega = endereco_entrega.replace(/[^a-zA-Z0-9]/g, "")
                endereco_entrega = parseFloat(endereco_entrega)
                this.pedido.empresa_servico_id = store_pedido.getters.empresa_cardapio.codigo_empresa_servico
                this.pedido.endereco_entrega_id = JSON.parse(sessionStorage.getItem('Código endereço de entrega')) == null ? store_pedido.getters.clientes.usuario.enderecos[0].codigo_endereco : endereco_entrega
                this.pedido.troco = parseFloat(this.valorDoTroco != null ? this.valorDoTroco.toFixed(2) : 0)
                this.pedido.sub_total = parseFloat(store_pedido.getters.total_pedido.toFixed(2))
                this.pedido.valor_beneficio = 0
                this.pedido.pedido_tipo_retiradas_id = this.pedido['pedido_tipo_entrega_id'] == '1' ? this.pedido.pedido_tipo_retiradas_id : ''
                this.pedido.valor_entrega = this.pedido['pedido_tipo_entrega_id'] == '1' ? this.frete.valor_frete : 0
                this.pedido.valor_total_pedido = parseFloat(store_pedido.getters.total_pedido.toFixed(2))
                this.pedido.pagamento.forma_pagamento = 4
                this.pedido.pagamento.valor_pagamento = this.pedido.valor_total_pedido

                this.montaItensDoPedido()
                
                if(store_pedido.getters.pedido_mesa_id != null || localStorage.getItem('pedido_mesa_id') != undefined){
                    this.pedido.pedido_id = store_pedido.getters.pedido_mesa_id || localStorage.getItem('pedido_mesa_id')
                }
                this.pedido.usuario_id = store_pedido.getters.clientes.usuario.id
                this.pedido.empresa_servico_id = store_pedido.getters.empresa_cardapio.codigo_empresa_servico
                this.pedido.sub_total = this.pedido.valor_total_pedido
                this.pedido.valor_total_pedido = store_pedido.getters.total_pedido
                this.pedido.mesa_id = store_pedido.getters.mesa_id || localStorage.getItem('mesa_id')

                
            
            },

            getValorTotal(pedido){
                console.log('getValorTotal')
                console.log(pedido)
                let total_ = 0
                total_ += pedido.preco * pedido.quantidade
                return total_
            },

            async montaItensDoPedido(){
                let temp_itens_prod = [];
                let temp_index_complementos = 0
                let carrinho = store_pedido.getters.carrinho

                for (let index = 0; index < carrinho.length; index++) {
                    const element = carrinho[index];

                    temp_itens_prod[index] ={
                        produto_id: element.id,
                        codigo_tamanho: element.codigo_tamanho,
                        quantidade: element.quantidade,
                        tipo_produto: element.tipo_produto,
                        observacoes: element.observacoes,
                        valor: this.getValorTotal(element),
                        complementos: [],
                        borda: element.borda,
                        massa: element.massa,
                        sabores: element.sabores,
                    }

                    if (element.grupo_complementos.length > 0) {
                        for (let indexExtra = 0; indexExtra < element.grupo_complementos.length; indexExtra++) {
                            const grupo = element.grupo_complementos[indexExtra];

                            if(grupo.complementos.length > 0){
                                for (let index2 = 0; index2 < grupo.complementos.length; index2++) {
                                    const element = grupo.complementos[index2];
                                    temp_itens_prod[index].complementos[temp_index_complementos] ={
                                        produto_complemento_id: element.id,
                                        quantidade: element.quantidade
                                    }
                                    temp_index_complementos++
                                    
                                }
                            }
                        }
                    }
                    temp_index_complementos = 0
                }

                this.pedido.produtos = temp_itens_prod
            },


			async buscaCep(val){
				if(val.length == 9){
					// faz a requisição para o back para coletar os grupos
					var resp = await store.dispatch('buscaCep',{cep:val.replace('-','')})
					// caso o status da resposta seja 200 (deu certo)
					if(resp.status == 200){
                        const dados = resp.data.dados;
						// atribui a resposta na variavel grupos
                        this.formData.rua = await this.titleize(dados.logradouro);
                        this.formData.cidade = await this.titleize(dados.cidade);
                        this.formData.estado = dados.sigla;
                        this.formData.bairro = await this.titleize(dados.bairro);
					}else{
						this.formData.rua = '';
						this.formData.cidade = '';
						this.formData.estado = '';
						this.formData.bairro = '';
					}
				}
			},
            async salvarCartao(){
                if(await this.verificaCampos()){
                    this.$swal({
                        title: 'Aguarde',
                        text: "Estamos validando e cadastrando seu cartão!",
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: false,
                        allowOutsideClick:false,
                        allowEscapeKey:false,
                    })

                    // faz a requisição para o back para coletar os grupos
                    var resp = await store.dispatch('salvarCartao', { dados: this.formData, codigo_empresa: this.codigo_empresa });

                    if(resp.status == 200){
                        if(!resp.data.sucesso){
                            this.$swal({
                                title: 'Atenção',
                                icon:'warning',
                                text: resp.data.message,
                                showCancelButton: false,
                                showCloseButton: false,
                                showConfirmButton: true,
                                confirmButtonText:'Ok'
                            })
                        }else{
                            this.$swal({
                                title: 'Atenção',
                                icon:'success',
                                text: 'Cartão cadastrado com sucesso!',
                                showCancelButton: false,
                                showCloseButton: false,
                                showConfirmButton: true,
                                confirmButtonText:'Ok'
                            }).then(() => {
                                this.cadastrar_cartao = false
                            })
                        }
                    }
                }else{
                    this.$swal({
                        title: 'Atenção',
                        icon:'warning',
					    text: 'Preencha todos os campos!',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: true,
                        confirmButtonText:'Ok'
                    })
                }
            },
            async gerarPagamento(){
                if(this.dados_cobranca.cartao_selecionado != ''){
                    this.$swal({
                        title: 'Aguarde',
                        text: "Estamos validando e realizando o pagamento!",
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: false,
                        allowOutsideClick:false,
                        allowEscapeKey:false,
                    })

                    // faz a requisição para o back para coletar os grupos
                    var resp = await store.dispatch('gerarPagamento', { dados_cobranca: this.dados_cobranca, codigo_empresa: this.codigo_empresa });

                    if(resp.status == 200){
                        if(!resp.data.sucesso){
                            this.$swal({
                                title: 'Atenção',
                                icon:'warning',
                                text: 'Erro ao processar pagamento!',
                                showCancelButton: false,
                                showCloseButton: false,
                                showConfirmButton: true,
                                confirmButtonText:'Ok'
                            })
                        }else{
                            this.$swal({
                                title: 'Atenção',
                                icon:'success',
                                text: 'Pagamento gerado com sucesso!',
                                showCancelButton: false,
                                showCloseButton: false,
                                showConfirmButton: true,
                                confirmButtonText:'Ok'
                            })
                        }

                        this.$emit('retornoTransaction',resp.data)
                    }
                }else{
                    this.$swal({
                        title: 'Atenção',
                        icon:'warning',
					    text: 'Preencha todos os campos!',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: true,
                        confirmButtonText:'Ok'
                    })
                }
            },
            async apagarCartao(codigo_cartao_id){
                this.$swal({
                    title: 'Atenção',
                    icon:'question',
                    text: 'Deseja realmente deletar este cartão?',
                    showCancelButton: true,
                    showCloseButton: false,
                    showConfirmButton: true,
                    confirmButtonText:'Sim',
                    cancelButtonText:'Não'
                }).then(async (result) => {
                    if(result.isConfirmed){
                        // faz a requisição para o back para coletar os grupos
                        var resp = await store.dispatch('apagarCartao', { codigo_cartao_id, codigo_empresa: this.codigo_empresa });

                        this.getCartoes();
                        
                        if(resp.status == 200){
                            if(!resp.data.sucesso){
                                this.$swal({
                                    title: 'Atenção',
                                    icon:'warning',
                                    text: 'Não foi possível apagar o Cartão ',
                                    showCancelButton: false,
                                    showCloseButton: false,
                                    showConfirmButton: true,
                                    confirmButtonText:'Ok'
                                })
                            }else{
                                this.$swal({
                                    title: 'Atenção',
                                    icon:'success',
                                    text: 'Cartão removido com sucesso!',
                                    showCancelButton: false,
                                    showCloseButton: false,
                                    showConfirmButton: true,
                                    confirmButtonText:'Ok'
                                }).then(() => {
                                    this.cadastrar_cartao = false
                                })
                            }
                        }
                    }
                })
            },
            async verificaCampos(){
                return this.formData.cardName != '' &&
                    this.formData.cardNumber !=  ''  &&
                    this.formData.cardMonth !=  '' &&
                    this.formData.cardYear !=  '' &&
                    this.formData.cardCvv !=  '' &&
                    this.formData.pais != '' &&
                    this.formData.cep != '' &&
                    this.formData.rua != '' &&
                    this.formData.cidade != '' &&
                    this.formData.estado != '' &&
                    this.formData.bairro != '' &&
                    this.formData.numero != ''
            },
            async getCartoes(){
                // faz a requisição para o back para coletar os grupos
                var resp = await store.dispatch('getCartoes', { codigo_empresa: this.codigo_empresa });

                this.cartoes = [];
                if(resp.status == 200){
                    if(resp.data.dados != undefined){
                        this.cartoes = resp.data.dados
                    }
                }
            },
            async titleize(text) {
                var words = text.toLowerCase().split(" ");
                for (var a = 0; a < words.length; a++) {
                    var w = words[a];
                    words[a] = w[0].toUpperCase() + w.slice(1);
                }
                return words.join(" ");
            }
		},
		mounted () {
            this.init()
		},
        watch:{
            cadastrar_cartao:async function () {
                await this.getCartoes();
            },
            'dados_cobranca.cartao_selecionado': function(newValue){
                this.$emit('verificaCartao',newValue)
                this.$emit('atualizaCobranca',this.dados_cobranca)
            },
            url_qrcode(newVal, oldVal) {
                if (newVal !== oldVal) {
                    // Quando o valor de url_qrcode mudar, força a re-renderização
                    this.$forceUpdate();
                }
            },
        },
        computed:{
            
        }
	}
</script>

<style lang="scss">
    #close-modal{
        background-color: transparent !important;
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 20px;
    }
    #opcoesDeCartao{
        .v-card{
            background-color: rgba(254, 130, 4, 0.8)!important;
            border-color: rgba(254, 130, 4, 0.8)!important;
        }
        .wrapper-cartao{
            width: 100%;
            padding: 0 20px;
            .wrapper-radio-text{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .v-card__title{
                    font-size: 20px !important;
                }
                @media (max-width: 1263px){
                    justify-content: center;
                    .v-card__title{
                        padding: 0;
                        padding-left: 15px;
                    }
                }
            }
            .wrapper-deletar-cartao{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-end;
                .v-card__actions{
                    button{
                        margin-top: 0!important;
                        margin-left: 0!important;
                        padding: 20px;
                        border-width: 2px;
                        border-color: #fff;
                        transition: all cubic-bezier(0, 1.03, 0.57, 0.92) .3s;
                        span{
                            transition: all cubic-bezier(0, 1.03, 0.57, 0.92) .3s;
                            font-family: 'Poppins';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            color: #fff;
                        }
                        &:before{
                            content: none;
                        }
                        &:hover{
                            background-color: #fff;
                            color: rgba(254, 130, 4, 0.8);
                            span{
                                color: rgba(254, 130, 4, 0.8);
                            }
                        }
                    }
                }
                @media (max-width: 1263px){
                    justify-content: center;
                }
            }
        }
        .wrapper-btn-cadastrar-cartao{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;
            padding-top: 30px;
            button{
                min-height: 50px;
                margin: 0!important;
                span{
                    font-size: 18px !important;
                }
                @media (max-width: 500px){
                    padding: 10px 20px !important;
                    span{
                        font-size: 15px !important;
                    }
                }
                @media (max-width: 328px){
                    min-height: 36px !important;
                    padding: 5px !important;
                    span{
                        font-size: 12px !important;
                        line-height: 20px !important;
                    }
                }
            }
            @media (max-width: 767px){
                justify-content: center;
            }
        }
        .wrapper-btn-confirmar-pagamento{
            border-top: 1px solid #242424;
            margin-top: 30px;
            padding: 30px 50px;
            .primary-v-button{
                width: 100%;
                min-height: 60px;
                border-width: 2px;
                border-color: green;
                background-color: green;
                &:before{
                    content: none;
                }
                &:hover{
                    background-color: #fff;
                    color: green;
                    span{
                        color: green;
                    }
                }
            }
        }
    }
</style>