<template>
    <div>
        <v-card-text>                              
            <v-radio-group
                v-model="radiosPagamentoEntrega"
                mandatory
            >
                <div class="wrapper-v-radio">                                                        
                    <v-icon color="red">mdi-credit-card-outline</v-icon>
                    <v-radio
                        label="Cartão de crédito"
                        value="3"
                    >
                    </v-radio>
                </div>
                <div class="wrapper-v-radio">                                                        
                    <v-icon color="red">mdi-credit-card</v-icon>
                    <v-radio
                        label="Cartão de débito"
                        value="2"
                    >
                    </v-radio>
                </div>
                <div class="wrapper-v-radio">
                    <v-icon color="red">mdi-cash</v-icon>
                    <v-radio
                        label="Dinheiro"
                        value="1"
                    >
                    </v-radio>
                </div>
            </v-radio-group>
        </v-card-text>
        <Loader v-if="loading"/>
    </div>
</template>

<script>
    import store from '@/store'
    import Loader from '@/components/Loader.vue'

    export default {
        name: 'PagamentoEntrega',
        components: {
			Loader
		},
        data: () => ({
            loading: false,
            radiosPagamentoEntrega: null,
            pedido:{
                empresa_servico_id: null,
                endereco_entrega_id: null,
                produtos:[],
                pedido_tipo_id: null,
                pagamento:{
                    tipo: null,
                    forma_pagamento: null,
                    valor_pagamento: null,
                },
                troco: null,
                sub_total: null,
                valor_beneficio: null,
                valor_total_pedido: null,
                valor_entrega: null,
                pagamento_tipo_id:null
            },
        }),
        props:{
            chave_financeiro:[String],
            retornoTransaction:[Function],
            codigo_usuario:[String],
            valor_cobranca:[Number],
            texto_valor:[String],
            itens:[Array],
            texto_botao_pagar:[String],
            frete:[Object],
            cliente:[Object],
            codigo_externo_cobranca:[String],
            atualizaFormaPagamento:[Function],
        },
        methods: {
            async montaItensDoPedido(){
                let temp_itens_prod = [
                    
                ]
                let temp_index_complementos = 0
                for (let index = 0; index < this.itens.length; index++) {
                    const element = this.itens[index];
                    temp_itens_prod[index] ={
                        produto_id: element.id,
                        quantidade: element.quantidade,
                        tipo_produto: "normal",
                        complementos: []
                    }
                    
                    if (element.grupo_complementos.length > 0) {
                        for (let indexExtra = 0; indexExtra < element.grupo_complementos.length; indexExtra++) {
                            const grupo = element.grupo_complementos[indexExtra];

                            if(grupo.complementos.length > 0){
                                for (let index2 = 0; index2 < grupo.complementos.length; index2++) {
                                    const element = grupo.complementos[index2];
                                    temp_itens_prod[index].complementos[temp_index_complementos] ={
                                        produto_complemento_id: element.id,
                                        quantidade: element.quantidade
                                    }
                                    temp_index_complementos++
                                    
                                }
                            }
                        }
                    }
                    temp_index_complementos = 0
                }
                this.pedido.produtos = temp_itens_prod
            },
            number_format(number, decimals, dec_point, thousands_sep) {
                number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
                var n = !isFinite(+number) ? 0 : +number,
                prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
                sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
                dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
                s = '',
                toFixedFix = function(n, prec) {
                    var k = Math.pow(10, prec);
                    return '' + (Math.round(n * k) / k).toFixed(prec);
                };
                s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
                if (s[0].length > 3) {
                    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
                }
                if ((s[1] || '').length < prec) {
                    s[1] = s[1] || '';
                    s[1] += new Array(prec - s[1].length + 1).join('0');
                }
                return s.join(dec);
            },
            replaceAll(campo,search,replace){
                return campo.replace(new RegExp('[' + search + ']', 'g'), replace); 
            },
            async criaNovoPedido(){
                this.loading = await true
                var resp = await store.dispatch('inserePedido', this.pedido)

                if (resp.sucesso) {
                    this.$swal({
                        title: 'Atenção',
                        icon:'success',
                        text: 'Seu pedido foi realizado com sucesso',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: true,
                        confirmButtonText:'Ok'
                    })

                    this.loading = await false
                }else{
                    this.$swal({
                        title: 'Atenção',
                        icon:'warning',
                        text: 'Pedido não realizado!',
                        showCancelButton: false,
                        showCloseButton: false,
                        showConfirmButton: true,
                        confirmButtonText:'Ok'
                    })
                    this.loading = await false
                }
                this.$emit('retornoTransaction',resp)
			},
        },
        watch:{
            radiosPagamentoEntrega:function(newValue){
                this.$emit('atualizaFormaPagamento',newValue)
            }
        },
        mounted(){
        }
    }
</script>