// importa o plugin de Request para fazer as requisições para o back
import Request from '@/plugins/request'

export default {

	state: {
		status: null,
		correia: null,
		pix_status:false

	},

	mutations: {
		SET_STATUS(state, status) {
			// seta o status do store de usuários
			state.status = status
		},

		SET_PIX_STATUS(state,pix){
			state.pix_status = pix
		},

		SET_CORREIA(state, correia) {
			state.correia = {
				dados: correia.dados,
				total: correia.total  || correia.dados.length
			}
		}
	},

	actions: {
		async salvarCartao({ commit }, dados) {
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'api/financeiro/salvarCartao/',
				// corpo da requisição
				body: dados,
				// método da requição(POST normalmente é usado para salvar dados do back)
				method: 'POST'
			})

			// seta o status do store de grupo para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},
		async getCartoes({ commit }, dados) {
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'api/financeiro/getCartoes/',
				// corpo da requisição
				body: dados,
				// método da requição(POST normalmente é usado para salvar dados do back)
				method: 'POST'
			})

			// seta o status do store de grupo para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},
		async apagarCartao({ commit }, dados) {
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'api/financeiro/apagarCartao/',
				// corpo da requisição
				body: dados,
				// método da requição(POST normalmente é usado para salvar dados do back)
				method: 'POST'
			})

			// seta o status do store de grupo para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},
		async gerarPagamento({ commit }, dados) {
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'api/financeiro/gerarPagamento/',
				// corpo da requisição
				body: dados,
				// método da requição(POST normalmente é usado para salvar dados do back)
				method: 'POST'
			})

			// seta o status do store de grupo para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		},

		async verificaPagamentoPix({commit}, dados){
			await commit('SET_STATUS', 'loading')

			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'api/financeiro/verificaPagamento/',
				// corpo da requisição
				body: dados,
				// método da requição(POST normalmente é usado para salvar dados do back)
				method: 'POST'
			})

			await commit('SET_PIX_STATUS', 'teste')

			return resp

		},

		async gerarQRCode({commit}, dados){
			await commit('SET_STATUS', 'loading')
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'api/financeiro/gerarPagamento/',
				// corpo da requisição
				body: dados,
				// método da requição(POST normalmente é usado para salvar dados do back)
				method: 'POST'
			})
			// seta o status do store de grupo para vazio 
			await commit('SET_STATUS', '')

			return resp
		},

		async buscaCep({ commit }, dados) {
			await commit('SET_STATUS', 'loading')
			// faz uma requisição para o back e coleta o resultado na variavel resp
			const resp = await Request({
				// url da rota do back para fazer a requisição
				route: 'api/financeiro/get_cep?cep=' + dados.cep,
				// corpo da requisição
				// método da requição(POST normalmente é usado para salvar dados do back)
				method: 'GET'
			})
			// seta o status do store de grupo para vazio 
			await commit('SET_STATUS', '')
			// retorna a resposta da requisição
			return resp
		}
	},
	
	getters: {
		status_correia: state => state.status,
		correia: state => state.correia,
		pix_status: state=> state.pix_status

	}
}