import Vue from 'vue'
import Vuex from 'vuex'

import pagamentos from "./pagamentos.js";

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		pagamentos
	},
})