<template>
  <div class="card-form">
    <div class="card-list">
      <Card
        :fields="fields"
        :labels="formData"
        :isCardNumberMasked="isCardNumberMasked"
        :randomBackgrounds="randomBackgrounds"
        :backgroundImage="backgroundImage"
      />
    </div>
    <div class="card-form__inner">
      <div class="card-input">
        <label for="cardNumber" class="card-input__label">Número do Cartão</label>
        <input
          type="tel"
          :id="fields.cardNumber"
          @input="changeNumber"
          @focus="focusCardNumber"
          @blur="blurCardNumber"
          class="card-input__input"
          :value="formData.numero_cartao"
          :maxlength="cardNumberMaxLength"
          data-card-field
          autocomplete="off"
        />
        <button
          class="card-input__eye"
          :class="{ '-active' : !isCardNumberMasked }"
          title="Show/Hide card number"
          tabindex="-1"
          :disabled="formData.numero_cartao === ''"
          @click="toggleMask"
        ></button>
      </div>
      <div class="card-input">
        <label for="cardName" class="card-input__label">Nome Cartão</label>
        <input
          type="text"
          :id="fields.cardName"
          v-letter-only
          @input="changeName"
          class="card-input__input"
          :value="formData.nome_cartao"
          data-card-field
          autocomplete="off"
        />
      </div>

      <div class="card-form__row">
        <div class="card-form__col">
          <div class="card-form__group">
            <label for="cardMonth" class="card-input__label">Data de Expiração Cartão</label>
            <select
              class="card-input__input -select"
              :id="fields.cardMonth"
              v-model="formData.mes_validade_cartao"
              @change="changeMonth"
              data-card-field
            >
              <option value disabled selected>Mês</option>
              <option
                v-bind:value="n < 10 ? '0' + n : n"
                v-for="n in 12"
                v-bind:disabled="n < minCardMonth"
                v-bind:key="n"
              >{{generateMonthValue(n)}}</option>
            </select>
            <select
              class="card-input__input -select"
              :id="fields.cardYear"
              v-model="formData.ano_validade_cartao"
              @change="changeYear"
              data-card-field
            >
              <option value disabled selected>Ano</option>
              <option
                v-bind:value="($index + minCardYear) % 100"
                v-for="(n, $index) in 12"
                v-bind:key="n"
              >{{$index + minCardYear}}</option>
            </select>
          </div>
        </div>
        <div class="card-form__col -cvv">
          <div class="card-input">
            <label for="cardCvv" class="card-input__label">CVV</label>
            <input
              type="tel"
              class="card-input__input"
              v-number-only
              :id="fields.cardCvv"
              maxlength="4"
              :value="formData.cvv_cartao"
              @input="changeCvv"
              data-card-field
              autocomplete="off"
            />
          </div>
        </div>
      </div>
      
      <h6>Endereço de Cobrança</h6>

      <div class="card-form__row">
        <div class="card-form__col">
          <div class="card-input">
            <label for="cardName" class="card-input__label">CEP</label>
            <input
              type="text"
              class="card-input__input"
              v-mask="'#####-###'" 
              v-model="formData.cep"
              autocomplete="off"
              v-on:keyup="verificaCep"
            />
          </div>
        </div>

        <div class="card-form__col">
          <div class="card-input">
            <label for="cardName" class="card-input__label">Bairro</label>
            <input
              type="text"
              class="card-input__input"
              v-model="formData.bairro"
              autocomplete="off"
            />
          </div>
        </div>

      </div>
      
      <div class="card-form__row">
        <div class="card-form__col">
          <div class="card-input">
            <label for="cardName" class="card-input__label">RUA</label>
            <input
              type="text"
              class="card-input__input"
              v-model="formData.rua"
              data-card-field
              autocomplete="off"
            />
          </div>
        </div>
      </div>

      <div class="card-form__row">
        <div class="card-form__col">
          <div class="card-input">
            <label for="cardName" class="card-input__label">Cidade</label>
            <input
              type="text"
              class="card-input__input"
              v-model="formData.cidade"
              data-card-field
              autocomplete="off"
            />
          </div>
        </div>

        <div class="card-form__col">
          <div class="card-input">
            <label for="cardName" class="card-input__label">Estado</label>
            <input
              type="text"
              class="card-input__input"
              v-model="formData.estado"
              data-card-field
              autocomplete="off"
            />
          </div>
        </div>
      </div>

      <div class="card-form__row">
        <div class="card-form__col">
          <div class="card-input">
            <label for="cardName" class="card-input__label">Número</label>
            <input
              type="text"
              class="card-input__input"
              v-model="formData.numero"
              data-card-field
              autocomplete="off"
            />
          </div>
        </div>

        <div class="card-form__col">
          <div class="card-input">
            <label for="cardName" class="card-input__label">País</label>
            <select
              class="card-input__input -select"
              v-model="formData.pais"
              data-card-field
            >
              <option value disabled selected>País</option>
              <option
                v-bind:value="n.value"
                v-for="n in paises"
                v-bind:key="n.value"
              >{{n.nome}}</option>
            </select>
          </div>
        </div>
      </div>

      <button class="card-form__button primary-button" v-on:click="invaildCard">Salvar Cartão</button>

      <button class="card-form__button primary-button cancelar-cartao" v-on:click="cancelarCartao">Cancelar</button>
    </div>
    
  </div>
</template>

<script>
import Card from './Card.vue'

export default {
  name: 'CardForm',
  directives: {
    'number-only': {
      bind (el) {
        function checkValue (event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, '')
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true
          }
          event.preventDefault()
        }
        el.addEventListener('keypress', checkValue)
      }
    },
    'letter-only': {
      bind (el) {
        function checkValue (event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault()
          }
          return true
        }
        el.addEventListener('keypress', checkValue)
      }
    },
  },
  props: {
    formData: {
      type: Object,
      default: () => {
        return {
          nome_cartao: '',
          numero_cartao: '',
          mes_validade_cartao: '',
          ano_validade_cartao: '',
          cvv_cartao: '',
          pais:'br',
          cep:'',
          rua:'',
          cidade:'',
          estado:'',
          bairro:'',
          numero:'',
        }
      }
    },
    buscaCep:[Function],
    backgroundImage: [String, Object],
    randomBackgrounds: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Card
  },
  data () {
    return {
      fields: {
        cardNumber: 'v-card-number',
        cardName: 'v-card-name',
        cardMonth: 'v-card-month',
        cardYear: 'v-card-year',
        cardCvv: 'v-card-cvv'
      },
      minCardYear: new Date().getFullYear(),
      isCardNumberMasked: true,
      mainCardNumber: this.cardNumber,
      cardNumberMaxLength: 19,
      paises:[
        {
          "nome":"Brasil",
          "value": "br"
        }
      ]
    }
  },
  computed: {
    minCardMonth () {
      if (this.formData.ano_validade_cartao === this.minCardYear) return new Date().getMonth() + 1
      return 1
    }
  },
  watch: {
    cardYear () {
      if (this.formData.mes_validade_cartao < this.minCardMonth) {
        this.formData.mes_validade_cartao = ''
      }
    }
  },
  mounted () {
    this.maskCardNumber()
  },
  methods: {
    generateMonthValue (n) {
      return n < 10 ? `0${n}` : n
    },
    changeName (e) {
      this.formData.nome_cartao = e.target.value
      this.$emit('input-card-name', this.formData.nome_cartao)
    },
    changeNumber (e) {
      this.formData.numero_cartao = e.target.value
      let value = this.formData.numero_cartao.replace(/\D/g, '')
      // american express, 15 digits
      if ((/^3[47]\d{0,13}$/).test(value)) {
        this.formData.numero_cartao = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
        this.cardNumberMaxLength = 17
      } else if ((/^3(?:0[0-5]|[68]\d)\d{0,11}$/).test(value)) { // diner's club, 14 digits
        this.formData.numero_cartao = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
        this.cardNumberMaxLength = 16
      } else if ((/^\d{0,16}$/).test(value)) { // regular cc number, 16 digits
        this.formData.numero_cartao = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{4})/, '$1 $2 ').replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ')
        this.cardNumberMaxLength = 19
      }
      // eslint-disable-next-line eqeqeq
      if (e.inputType == 'deleteContentBackward') {
        let lastChar = this.formData.numero_cartao.substring(this.formData.numero_cartao.length, this.formData.numero_cartao.length - 1)
        // eslint-disable-next-line eqeqeq
        if (lastChar == ' ') { this.formData.numero_cartao = this.formData.numero_cartao.substring(0, this.formData.numero_cartao.length - 1) }
      }
      this.$emit('input-card-number', this.formData.numero_cartao)
    },
    changeMonth () {
      this.$emit('input-card-month', this.formData.mes_validade_cartao)
    },
    verificaCep:function(){
      this.$emit('busca-cep', this.formData.cep)
    },
    changeYear () {
      this.$emit('input-card-year', this.formData.ano_validade_cartao)
    },
    changeCvv (e) {
      this.formData.cvv_cartao = e.target.value
      this.$emit('input-card-cvv', this.formData.cvv_cartao)
    },
    invaildCard () {
      this.$emit('salvar-cartao', this.formData)
    },
    cancelarCartao(){
      this.$emit('cancelar-cartao')
    },
    blurCardNumber () {
      if (this.isCardNumberMasked) {
        this.maskCardNumber()
      }
    },
    maskCardNumber () {
      this.formData.numero_cartao_sem_mascara = this.formData.numero_cartao
      this.mainCardNumber = this.formData.numero_cartao
      let arr = this.formData.numero_cartao.split('')
      arr.forEach((element, index) => {
        if (index > 4 && index < 14 && element.trim() !== '') {
          arr[index] = '*'
        }
      })
      this.formData.numero_cartao = arr.join('')
    },
    unMaskCardNumber () {
      this.formData.numero_cartao = this.mainCardNumber
    },
    focusCardNumber () {
      this.unMaskCardNumber()
    },
    toggleMask () {
      this.isCardNumberMasked = !this.isCardNumberMasked
      if (this.isCardNumberMasked) {
        this.maskCardNumber()
      } else {
        this.unMaskCardNumber()
      }
    }
  }
}
</script>

<style lang="scss">
  @import '../assets/style.scss';
  .cancelar-cartao{
    border-color: #000 !important;
    background-color: #000 !important;
	&:hover{
		border-color: #000 !important;
		background-color: transparent !important;
		color: #000 !important;
	}
  }
  .card-item{
    z-index: 1;
    @media (max-width: 371px){
      display: none;
    }
  }
  .swal2-container{
	.swal2-modal{
		display: flex;
		flex-direction: column;
		.swal2-header{
			.swal2-title{
				padding: 10px 30px 0;
			}
		}
		.swal2-content{
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
  }
</style>